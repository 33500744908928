import { CombinedState, createAsyncThunk } from '@reduxjs/toolkit';
import { IncomingMessage, ServerResponse } from 'http';
import { getCookie, setCookie } from 'cookies-next';
import { State as RootState } from 'models/redux-state';
import Axios from 'utils/axios';
import { getCurrentUrl } from 'utils/get-current-url';
import { NAME, VISITS_KEY } from './constants';
import * as Actions from './actions';

export const increaseVisits = createAsyncThunk<
  void,
  { req: IncomingMessage; res: ServerResponse },
  { state: CombinedState<RootState> }
>(`${NAME}/increase-visits`, async ({ req, res }, thunkAPI) => {
  const visits = getCookie(VISITS_KEY, { req, res });
  const increasedVisits = (parseInt(visits as string) || 0) + 1;
  setCookie(VISITS_KEY, increasedVisits, {
    req,
    res,
    httpOnly: false,
    sameSite: true,
    // maxAge is a year from now, unless the person revisits the site within a year. Then it will be updated with a year again.
    maxAge: 365 * 24 * 60 * 60,
  });
  thunkAPI.dispatch(Actions.setCookie({ visits: increasedVisits }));
});

export const fetchOnlineCount = createAsyncThunk<void, undefined, { state: CombinedState<RootState> }>(
  `${NAME}/fetch-online-count`,
  async (_, thunkAPI) => {
    const { data } = await Axios.get<{ count: number }>('/api/app/count');
    thunkAPI.dispatch(Actions.updateCount(data.count));
  },
);

export const fetchCookies = createAsyncThunk<void, undefined, { state: CombinedState<RootState> }>(
  `${NAME}/fetch-cookie`,
  async (cookie, thunkAPI) => {
    const visits = getCookie(VISITS_KEY);
    thunkAPI.dispatch(Actions.setCookie({ visits: parseInt(visits as string) || 0 }));
  },
);

export const updateHost = createAsyncThunk<
  void,
  { req: IncomingMessage | undefined; asPath: string; locale: string },
  { state: CombinedState<RootState> }
>(`${NAME}/update-host`, async (context, thunkAPI) => {
  const host = getCurrentUrl(context);
  thunkAPI.dispatch(Actions.setHost(host));
});
