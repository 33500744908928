export default function getConfig() {
  return {
    serverRuntimeConfig: {
      NODE_ENV: process.env.NODE_ENV,
      HOST: process.env.HOST,
      BASE_URL: process.env.BASE_URL,
      BEARER_TOKEN: process.env.BEARER_TOKEN,
      SESSION_COOKIE: process.env.SESSION_COOKIE,
      AUTHENTICATED_COOKIE: process.env.AUTHENTICATED_COOKIE,
      GTM_ID: process.env.GTM_ID,
      PRO_IP_API_KEY: process.env.PRO_IP_API_KEY,
      AUTHENTICATION_TOKEN: process.env.AUTHENTICATION_TOKEN,
    },
  };
}
