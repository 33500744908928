export enum StreakStates {
  Filled = 'filled',
  Skipped = 'skipped',
  Empty = 'empty',
}

export type StreakItem = {
  state: StreakStates;
  title: string;
  day: number;
  active?: boolean;
};
