import styled, { keyframes } from 'styled-components';
import { rgba } from 'polished';
import { colors, index, IndexOptions } from 'assets/styles';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${index(IndexOptions.LOADER)};
  width: 100%;
  height: 0.4rem;
  background-color: ${({ $isDark }) => rgba($isDark ? colors.white : colors.tint, 0.2)};
`;

const load = keyframes`
  from {
    width: 0;
  }
  
  to {
    width: 90%;
  }
`;

export const Loader = styled.div`
  width: 90%;
  height: 0.4rem;
  background-color: ${({ $isDark }) => ($isDark ? colors.white : colors.tint)};

  animation: ${load} 500ms ease-in-out;
`;
