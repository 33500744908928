import { useEffect, useState } from 'react';
import { SignedUrl } from 'models/track';
import { useBoundDispatch, useSelectorWithProps } from 'domains/redux/utils';
import * as Thunks from './thunks';
import * as Selectors from './selectors';

export const useSignedUrl = (uid?: string, isPremium?: boolean): [signedUrl: SignedUrl, loading: boolean] => {
  const fetchSignedUrl = useBoundDispatch(Thunks.fetchSignedUrl);
  const signedUrl = useSelectorWithProps(Selectors.getSignedUrlByUid, { uid }, [uid]);
  const [loading, setLoading] = useState(!!signedUrl);

  useEffect(() => {
    if (!uid) {
      return;
    }

    if (!signedUrl) {
      setLoading(true);
    }
    // still call the fetch, since the track could be outdated
    fetchSignedUrl({ uid, isPremium }).then(() => setLoading(false));
  }, [fetchSignedUrl, signedUrl, uid]);

  return [signedUrl, loading];
};
