import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { Quote } from 'models/quote';
import { QuoteCategory } from 'models/quote-category';
import { State } from './models';
import * as Actions from './actions';

const initialState: State = {
  categories: {},
  items: {},
};

export function onUpdateCategories(state: State, { payload }: PayloadAction<{ categories: QuoteCategory[] }>): void {
  state.categories = payload.categories.reduce((result, category) => {
    result[category.uid] = category;
    return result;
  }, state.categories);
}

export function onUpdateQuotes(state: State, { payload }: PayloadAction<{ quotes: Quote[] }>): void {
  state.items = payload.quotes.reduce((result, quote) => {
    result[quote.uid] = quote;
    return result;
  }, state.items);
}

export default createReducer(initialState, {
  [String(Actions.updateCategories)]: onUpdateCategories,
  [String(Actions.updateQuotes)]: onUpdateQuotes,
});
