import { CombinedState } from '@reduxjs/toolkit';
import { Meditation } from 'models/meditation';
import { createSelector } from 'reselect';
import { State as RootState } from 'models/redux-state';
import { State } from './models';
import { NAME } from './constants';

const getUidProp = (_, { uid }: { uid: string }) => uid;

export const getState = (state: CombinedState<RootState>): State => state[NAME];

export const getMomentsMap = createSelector(getState, (state) => state.moments);

export const getMoments = createSelector(getMomentsMap, (momentsMap) => Object.values(momentsMap));

export const getMomentByUid = createSelector(getUidProp, getMomentsMap, (uid, momentsMap) => momentsMap[uid]);

export const getMomentByMeditationUid = createSelector(getUidProp, getMoments, (uid, moments) =>
  moments.find(({ tracks }) => tracks.includes(uid)),
);

export const getMeditationsMap = createSelector(getState, (state) => state.items);

export const getSearchableMeditationsMap = createSelector(getMeditationsMap, (meditationsMap) =>
  Object.entries(meditationsMap).reduce<Record<string, Meditation>>((result, [key, value]) => {
    if (value.isSearchable) {
      result[key] = value;
    }
    return result;
  }, {}),
);

export const getSearchableMeditations = createSelector(getSearchableMeditationsMap, (meditationsMap) =>
  Object.values(meditationsMap),
);

export const getMeditations = createSelector(getMeditationsMap, (meditationsMap) => Object.values(meditationsMap));

export const getMeditationByUid = createSelector(
  getUidProp,
  getMeditationsMap,
  (uid, meditationsMap) => meditationsMap[uid],
);

export const getMeditationByTrackUid = createSelector(getUidProp, getMeditations, (uid, meditations) =>
  meditations.find(({ tracks }) => tracks.includes(uid)),
);
