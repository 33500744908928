import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { Course } from 'models/course';
import * as Actions from './actions';
import { State } from './models';

const initialState: State = {};

export function onUpdate(state: State, { payload }: PayloadAction<{ courses: Course[] }>): State {
  return payload.courses.reduce((result, item) => ({ ...result, [item.uid]: item }), state);
}

export default createReducer(initialState, {
  [String(Actions.update)]: onUpdate,
});
