import { DayParts, DayPartTime } from 'models/day-parts';

export enum LOCALES {
  NL = 'nl',
  EN = 'en',
}

export const LOCALES_ORDERED = [LOCALES.EN, LOCALES.NL];

export const LEANPLUM_VARIABLES_KEY = {
  [LOCALES.NL]: 'web-app',
  [LOCALES.EN]: 'web-app-en',
};

export const RETURN_URL_QUERY_PARAM = 'return_url';

export const MUSIC_TRACK_QUERY_PARAM = 'music_track';

export const DAY_PART_START_TIMES: Record<DayParts, DayPartTime> = {
  [DayParts.Morning]: { hours: 4, minutes: 30 },
  [DayParts.Afternoon]: { hours: 12, minutes: 0 },
  [DayParts.Evening]: { hours: 18, minutes: 0 },
};

export const SEARCH_QUERY_PARAM = 'search';

export const MIN_SEARCH_CHARACTERS = 3;

export const MIN_SEARCH_CONTENT_SUGGESTIONS = 5;

export const PREMIUM_MODAL_VISITS = 6;

export const MAX_PAGE_SIZE = 50;
