import { CombinedState } from '@reduxjs/toolkit';
import { State as RootState } from 'models/redux-state';
import { createSelector } from 'reselect';
import { NAME } from './constants';
import { State } from './models';

const getUidProp = (_, { uid }: { uid: string }) => uid;

export const getState = (state: CombinedState<RootState>): State => state[NAME];

export const getItemsMap = createSelector(getState, (state) => state.items);

export const getItems = createSelector(getItemsMap, (items) => Object.values(items));

export const getItemByUid = createSelector(getUidProp, getItemsMap, (uid, itemsMap) => itemsMap[uid]);

export const getItemByTrackUid = createSelector(getUidProp, getItems, (uid, items) =>
  items.find(({ tracks }) => tracks.includes(uid)),
);
