import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { MusicCategory } from 'models/music-category';
import { MusicTrack } from 'models/music-track';
import * as Actions from './actions';
import { State } from './models';

const initialState: State = {
  categories: {},
  items: {},
};

export function onUpdateMusicTracks(state: State, { payload }: PayloadAction<{ musicTracks: MusicTrack[] }>): void {
  state.items = payload.musicTracks.reduce(
    (result, category) => ({ ...result, [category.uid]: category }),
    state.items,
  );
}

export function onUpdateCategories(state: State, { payload }: PayloadAction<{ categories: MusicCategory[] }>): void {
  state.categories = payload.categories.reduce(
    (result, category) => ({ ...result, [category.uid]: category }),
    state.categories,
  );
}

export default createReducer(initialState, {
  [String(Actions.updateMusicTracks)]: onUpdateMusicTracks,
  [String(Actions.updateCategories)]: onUpdateCategories,
});
