export enum DayParts {
  Morning = 'morning',
  Afternoon = 'afternoon',
  Evening = 'evening',
}

export type DayPartTime = {
  hours: number;
  minutes: number;
};
