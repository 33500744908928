export enum ActionEvents {
  SearchResultClicked = 'search_result_clicked',
  ProgramStarted = 'program_started',
  ProgramFinished = 'program_finished',
  PlaylistStarted = 'playlist_started',
  PlaylistFinished = 'playlist_finished',
  MyProfileResendActivationEmail = 'my_profile_resend_activation_mail',
  GenericError = 'generic_error',
  SessionStarted = 'session_started',
  SessionCanceled = 'session_cancelled',
  SessionFinished = 'session_finished',
  SharingEventDone = 'sharing_event_done',
  ScreenViewed = 'screen_viewed',
}
