import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { YogaItem } from 'models/yoga';
import * as Actions from './actions';
import { State } from './models';

const initialState: State = {
  items: {},
};

export function onUpdateItems(state: State, { payload }: PayloadAction<{ items: YogaItem[] }>): void {
  state.items = payload.items.reduce((result, item) => {
    result[item.uid] = item;
    return result;
  }, state.items);
}

export default createReducer(initialState, {
  [String(Actions.updateItems)]: onUpdateItems,
});
