export enum Options {
  PAGE = 'page',
  NAVIGATION = 'navigation',
  MEDIATION_PLAYER = 'meditation-player',
  MINIMIZED_MUSIC_PLAYER = 'minimized-music-player',
  MAXIMIZED_MUSIC_PLAYER = 'maximized-music-player',
  PROFILE_POPUP = 'profile-popup',
  SEARCH = 'search',
  LOADER = 'loader',
  MODAL = 'modal',
}

const order = [
  Options.PAGE,
  Options.MINIMIZED_MUSIC_PLAYER,
  Options.NAVIGATION,
  Options.MEDIATION_PLAYER,
  Options.MAXIMIZED_MUSIC_PLAYER,
  Options.PROFILE_POPUP,
  Options.SEARCH,
  Options.LOADER,
  Options.MODAL,
];

export default function index(option: Options): number {
  return order.findIndex((value) => value === option);
}
