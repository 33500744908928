import { useCallback, useEffect, useRef, useState } from 'react';

export default function useTimePassed(milliseconds = 30000): [timePassed: boolean, resetTimer: () => void] {
  const [timePassed, setIsSession] = useState<boolean>(false);
  const timeout = useRef<number>();

  const resetTimer = useCallback(() => {
    setIsSession(false);
    if (timeout.current) {
      window.clearTimeout(timeout.current);
    }
    timeout.current = window.setTimeout(() => {
      setIsSession(true);
    }, milliseconds);
  }, [milliseconds]);

  useEffect(() => {
    resetTimer();

    return () => window.clearTimeout(timeout.current);
  }, [resetTimer]);

  return [timePassed, resetTimer];
}
