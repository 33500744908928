import { MenuLinks } from 'data/menus';

export default function isPartOfUrl(string: string | string[], url: string): boolean {
  url = url.toLowerCase();
  const splicedUrl = url.split('/');
  const options = typeof string === 'string' ? [string] : string;

  return options.reduce((result, option) => {
    const splicedOption = option.split('/');
    if (result) {
      return result;
    }

    return splicedOption.reduce((result, subOption, index) => {
      if (!result) {
        return result;
      }
      return splicedUrl[index] === subOption;
    }, true);
  }, false);
}

export function getRouteByPath(path: string, routes: string[] = Object.values(MenuLinks)): string {
  path = path.toLowerCase();
  return routes.find((route) => isPartOfUrl(route, path));
}
