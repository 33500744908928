import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { LOCALES } from 'config/constants';
import { SearchTerm, SearchTermContent } from 'models/search';
import { State } from './models';
import * as Actions from './actions';

const initialState: State = {
  searchStrings: {},
  searchTerms: {},
  popularTerms: {},
  searchTermContent: {},
};

export function onUpdateSearchTerms(state: State, { payload }: PayloadAction<{ searchTerms: SearchTerm[] }>): void {
  state.searchTerms = payload.searchTerms.reduce((result, searchTerm) => {
    result[searchTerm.uniqueId] = searchTerm;
    return result;
  }, state.searchTerms);
}

export function onAddSearch(
  state: State,
  {
    payload,
  }: PayloadAction<{
    locale: LOCALES;
    searchString: string;
    suggestions: { terms: number[]; meditations: string[]; musicTracks: string[]; courses: string[] };
  }>,
): void {
  if (!state.searchStrings[payload.locale]) {
    state.searchStrings[payload.locale] = {};
  }

  state.searchStrings[payload.locale][payload.searchString] = payload.suggestions;
}

export function onUpdatePopularTerms(
  state: State,
  { payload }: PayloadAction<{ locale: LOCALES; popularTerms: number[] }>,
): void {
  state.popularTerms[payload.locale] = payload.popularTerms;
}

export function onSetSearchTermContent(
  state: State,
  { payload }: PayloadAction<{ id: number; content: SearchTermContent }>,
): void {
  state.searchTermContent[payload.id] = payload.content;
}

export default createReducer(initialState, {
  [String(Actions.updateSearchTerms)]: onUpdateSearchTerms,
  [String(Actions.addSearch)]: onAddSearch,
  [String(Actions.updatePopularTerms)]: onUpdatePopularTerms,
  [String(Actions.setSearchTermContent)]: onSetSearchTermContent,
});
