import React, { SetStateAction, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { SignedUrl } from 'models/track';
import { getRouteByPath } from 'utils/is-part-of-url';
import { useSelectorWithProps } from 'domains/redux/utils';
import { useSignedUrl } from 'domains/audio-tracks';
import { Selectors as musicSelectors } from 'domains/music';
import { Selectors as logicMusicSelectors } from 'logic-domains/music';
import { MenuLinks } from 'data/menus';

export const useMusicTrackSignedUrl = (
  musicTrackUid: string,
): [
  signedUrl: SignedUrl,
  loading: boolean,
  preferredDurationIndex: number,
  setPreferredDurationIndex: React.Dispatch<SetStateAction<number>>,
] => {
  const musicTrack = useSelectorWithProps(musicSelectors.getMusicTrackByUid, { uid: musicTrackUid }, [musicTrackUid]);
  const tracks = useSelectorWithProps(logicMusicSelectors.getMusicTrackTracksByUid, { uid: musicTrackUid }, [
    musicTrackUid,
  ]);
  const [preferredDurationIndex, setPreferredDurationIndex] = useState<number>(1);

  const [signedUrl, loading] = useSignedUrl(
    tracks?.[preferredDurationIndex]?.uid ?? tracks?.[(tracks?.length ?? 1) - 1]?.uid,
    musicTrack?.premium,
  );

  return [signedUrl, loading, preferredDurationIndex, setPreferredDurationIndex];
};

const detailRoutes = [
  MenuLinks.Meditations as string,
  MenuLinks.Moments as string,
  MenuLinks.Music as string,
  MenuLinks.Programs as string,
];
const excludedRoutes = [MenuLinks.MusicCategories as string];

export const useIsDetailScreen = (): boolean => {
  const router = useRouter();
  const { asPath } = router;

  return useMemo(() => {
    // if excluded route; early exit.
    if (excludedRoutes.includes(asPath)) {
      return false;
    }
    // if no detailRoute; early exit.
    const detailRoute = getRouteByPath(asPath, detailRoutes);
    if (!detailRoute) {
      return false;
    }
    // if no child of route; false
    const childPath = asPath.substring(detailRoute.length);
    return childPath.length > 1;
  }, [asPath]);
};

const mobileMenuRoutes = [
  MenuLinks.ForYou as string,
  MenuLinks.Meditations as string,
  MenuLinks.Music as string,
  MenuLinks.Programs as string,
  MenuLinks.Favorites as string,
];

export const useHasMobileMenu = (): boolean => {
  const router = useRouter();
  const { asPath } = router;

  return useMemo(() => {
    // if not on mobile route; early exit
    const route = getRouteByPath(asPath, mobileMenuRoutes);
    if (!route) {
      return false;
    }
    // if no child of router; true
    const childPath = asPath.substring(route.length);
    return childPath.length <= 0;
  }, [asPath]);
};
