import { ImageSet } from 'models/image';

export enum CollectionTypes {
  Meditation = 'meditation',
  MusicTrack = 'musicTrack',
  External = 'external',
}

export type Collection = {
  uid: string;
  type: CollectionTypes;
};

export type SpotlightBaseCollection = {
  type: CollectionTypes;
  title: string;
  label?: string;
  backgroundImage: ImageSet;
};

export type SpotlightMeditationCollection = SpotlightBaseCollection & {
  meditationUid: string;
};

export type SpotlightMusicTrackCollection = SpotlightBaseCollection & {
  musicTrackUid: string;
};

export type SpotlightExternalCollection = SpotlightBaseCollection & {
  link: string;
};

export type SpotlightCollection =
  | SpotlightMeditationCollection
  | SpotlightMusicTrackCollection
  | SpotlightExternalCollection;
