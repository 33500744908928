import { combineReducers } from 'redux';
import { name as appName, reducer as appReducer } from 'domains/app';
import { name as audioTracksName, reducer as audioTracksReducer } from 'domains/audio-tracks';
import { name as coursesName, reducer as coursesReducer } from 'domains/courses';
import { name as errorsName, reducer as errorsReducer } from 'domains/errors';
import { name as meditationsName, reducer as meditationsReducer } from 'domains/meditations';
import { name as musicName, reducer as musicReducer } from 'domains/music';
import { name as yogaName, reducer as yogaReducer } from 'domains/yoga';
import { name as flowName, reducer as flowReducer } from 'domains/flow';
import { name as sectionsName, reducer as sectionsReducer } from 'domains/sections';
import { name as userName, reducer as userReducer } from 'domains/user';
import { name as quotesName, reducer as quotesReducer } from 'domains/quotes';
import { name as searchName, reducer as searchReducer } from 'domains/search';

const reducers = {
  [appName]: appReducer,
  [audioTracksName]: audioTracksReducer,
  [coursesName]: coursesReducer,
  [errorsName]: errorsReducer,
  [meditationsName]: meditationsReducer,
  [musicName]: musicReducer,
  [yogaName]: yogaReducer,
  [flowName]: flowReducer,
  [sectionsName]: sectionsReducer,
  [userName]: userReducer,
  [quotesName]: quotesReducer,
  [searchName]: searchReducer,
};

Object.freeze(reducers);

export default combineReducers({
  ...reducers,
});
