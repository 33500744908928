import { CombinedState, createAsyncThunk } from '@reduxjs/toolkit';
import { Narrator } from 'models/narrator';
import { State as RootState } from 'models/redux-state';
import { SignedUrl } from 'models/track';
import Axios from 'utils/axios';
import { NAME } from './constants';
import * as Actions from './actions';
import * as Selectors from './selectors';

export const fetchNarrators = createAsyncThunk<void, undefined, { state: CombinedState<RootState> }>(
  `${NAME}/fetch-narrators`,
  async (arg, thunkAPI) => {
    const _narrators = Selectors.getNarrators(thunkAPI.getState());

    if (_narrators.length) {
      return;
    }

    const { data } = await Axios.get<{ items: Narrator[] }>('/api/audio/narrators');
    thunkAPI.dispatch(Actions.updateNarrators(data.items));
  },
);

export const fetchSignedUrl = createAsyncThunk<
  boolean,
  { uid: string; isPremium?: boolean },
  { state: CombinedState<RootState> }
>(`${NAME}/fetch-signed-url`, async ({ uid, isPremium }, thunkAPI) => {
  const _signedUrl = Selectors.getSignedUrlByUid(thunkAPI.getState(), { uid });

  if (_signedUrl) {
    const expireDate = new Date(_signedUrl.expireTimestamp);

    // if the time hasn't expired, we don't need to fetch again.
    if (expireDate.getTime() - new Date().getTime() > 0) {
      return true;
    }
  }

  const { data } = await Axios.get<{ item: SignedUrl }>(`/api/audio/signed-urls/${uid}`, {
    withCredentials: true,
    params: { isPremium },
  });
  if (!data.item) {
    return false;
  }

  thunkAPI.dispatch(Actions.updateSignedUrls([data.item]));
  return true;
});
