import { createSelector } from 'reselect';
import { State as RootState } from 'models/redux-state';
import { CombinedState } from 'redux';
import { NAME } from './constants';
import { State } from './models';

export const getLocaleProp = (_, { locale }: { locale: string }) => locale;

export const getState = (state: CombinedState<RootState>): State => state[NAME];

export const getPrograms = createSelector(getState, (state) => state.programs);
export const getLocalizedPrograms = createSelector(getPrograms, getLocaleProp, (programs, locale) => programs[locale]);

export const getMoments = createSelector(getState, (state) => state.moments);
export const getLocalizedMoments = createSelector(getMoments, getLocaleProp, (moments, locale) => moments[locale]);

export const getMoment = createSelector(getState, (state) => state.moment);

export const getSpotlight = createSelector(getState, (state) => state.spotlight);

export const getNew = createSelector(getState, (state) => state.new);

export const getTrending = createSelector(getState, (state) => state.trending);

export const getDailyQuotes = createSelector(getState, (state) => state.dailyQuotes);

export const getNewMeditations = createSelector(getState, (state) => state.newMeditations);
export const getLocalizedNewMeditations = createSelector(
  getNewMeditations,
  getLocaleProp,
  (moments, locale) => moments[locale],
);

export const getTrendingMeditations = createSelector(getState, (state) => state.trendingMeditations);
export const getLocalizedTrendingMeditations = createSelector(
  getTrendingMeditations,
  getLocaleProp,
  (moments, locale) => moments[locale],
);

export const getMusicCategories = createSelector(getState, (state) => state.musicCategories);
export const getLocalizedMusicCategories = createSelector(
  getMusicCategories,
  getLocaleProp,
  (musicCategories, locale) => musicCategories[locale],
);

export const getNewMusicTracks = createSelector(getState, (state) => state.newMusic);
export const getLocalizedNewMusicTracks = createSelector(
  getNewMusicTracks,
  getLocaleProp,
  (moments, locale) => moments[locale],
);

export const getTrendingMusicTracks = createSelector(getState, (state) => state.trendingMusic);
export const getLocalizedTrendingMusicTracks = createSelector(
  getTrendingMusicTracks,
  getLocaleProp,
  (moments, locale) => moments[locale],
);
