import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIsDark } from 'domains/user';
import Router from 'next/router';
import { Container, Loader } from './loading-indicator.style';

const LoadingIndicator: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const delayRef = useRef<number>();
  const isDark = useIsDark();

  const onRouteChangeStart = useCallback(() => {
    window.clearTimeout(delayRef.current);
    delayRef.current = window.setTimeout(() => setIsLoading(true), 500);
  }, [setIsLoading]);

  const onRouteChangeComplete = useCallback(() => {
    window.clearTimeout(delayRef.current);
    setIsLoading(false);
  }, [setIsLoading]);

  useEffect(() => {
    Router.events.on('routeChangeStart', onRouteChangeStart);
    Router.events.on('routeChangeComplete', onRouteChangeComplete);
    Router.events.on('routeChangeError', onRouteChangeComplete);

    return () => {
      Router.events.off('routeChangeStart', onRouteChangeStart);
      Router.events.off('routeChangeComplete', onRouteChangeComplete);
      Router.events.off('routeChangeError', onRouteChangeComplete);
    };
  }, [onRouteChangeStart, onRouteChangeComplete]);

  return isLoading ? (
    <Container $isDark={isDark}>
      <Loader $isDark={isDark} />
    </Container>
  ) : null;
};

export default LoadingIndicator;
