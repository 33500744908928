import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { LOCALES } from 'config/constants';
import { CourseProgress } from 'models/course';
import { RecentlyPlayedMeditation, RecentlyPlayedMusicTrack } from 'models/recently-played';
import { User, UserStatistics } from 'models/user';
import { State } from './models';
import * as Actions from './actions';

const initialState: State = {
  locale: LOCALES.EN,
  offset: undefined,
  timezone: undefined,
  timezones: undefined,
  user: undefined,
  statistics: undefined,
  recentlyPlayedMeditations: {},
  recentlyPlayedMusicTracks: {},
  favoriteMeditations: {},
  favoriteMusicTracks: {},
  courseProgress: {},
};

export function onSetUser(state: State, { payload }: PayloadAction<{ user: User }>): void {
  state.user = payload.user;
}

export function onSetStatistics(state: State, { payload }: PayloadAction<{ statistics: UserStatistics }>): void {
  state.statistics = payload.statistics;
}

export function onSetWeekStreak(
  state: State,
  { payload }: PayloadAction<{ weekStreak: Record<string, number> }>,
): void {
  state.statistics = { ...(state.statistics ?? {}), weekMeditationStreak: payload.weekStreak };
}

export function onSetRecentlyPlayedMeditations(
  state: State,
  { payload }: PayloadAction<{ uid: string; recentlyPlayed: RecentlyPlayedMeditation[] }>,
): void {
  state.recentlyPlayedMeditations[payload.uid] = payload.recentlyPlayed;
}

export function onSetRecentlyPlayedMusicTracks(
  state: State,
  { payload }: PayloadAction<{ uid: string; recentlyPlayed: RecentlyPlayedMusicTrack[] }>,
): void {
  state.recentlyPlayedMusicTracks[payload.uid] = payload.recentlyPlayed;
}

export function onSetFavoriteMeditations(
  state: State,
  { payload }: PayloadAction<{ uid: string; favorites: string[] }>,
): void {
  state.favoriteMeditations[payload.uid] = payload.favorites;
}

export function onSetFavoriteMusicTracks(
  state: State,
  { payload }: PayloadAction<{ uid: string; favorites: string[] }>,
): void {
  state.favoriteMusicTracks[payload.uid] = payload.favorites;
}

export function onSetLocale(state: State, { payload }: PayloadAction<{ locale: LOCALES }>): void {
  state.locale = payload.locale;
}

export function onSetTimezone(state: State, { payload }: PayloadAction<{ timezone: string }>): void {
  state.timezone = payload.timezone;
}

export function onSetTimezones(state: State, { payload }: PayloadAction<{ timezones: string[] }>): void {
  state.timezones = payload.timezones;
}

export function onSetUTCOffset(state: State, { payload }: PayloadAction<{ offset: number }>): void {
  state.offset = payload.offset;
}

export function onSetCourseProgress(
  state: State,
  { payload }: PayloadAction<{ uid: string; progress: CourseProgress }>,
): void {
  state.courseProgress[payload.uid] = payload.progress;
}

export default createReducer(initialState, {
  [String(Actions.setUser)]: onSetUser,
  [String(Actions.setStatistics)]: onSetStatistics,
  [String(Actions.setWeekStreak)]: onSetWeekStreak,
  [String(Actions.setRecentlyPlayedMeditations)]: onSetRecentlyPlayedMeditations,
  [String(Actions.setRecentlyPlayedMusicTracks)]: onSetRecentlyPlayedMusicTracks,
  [String(Actions.setFavoriteMeditations)]: onSetFavoriteMeditations,
  [String(Actions.setFavoriteMusicTracks)]: onSetFavoriteMusicTracks,
  [String(Actions.setCourseProgress)]: onSetCourseProgress,
  [String(Actions.setLocale)]: onSetLocale,
  [String(Actions.setTimezone)]: onSetTimezone,
  [String(Actions.setTimezones)]: onSetTimezones,
  [String(Actions.setUTCOffset)]: onSetUTCOffset,
});
