import { Meditation } from 'models/meditation';
import { Moment } from 'models/moment';
import createActionDomain from 'utils/create-action-domain';
import { NAME } from './constants';

const createAction = createActionDomain(NAME);

export const updateMeditations = createAction('update-meditations', (meditations: Meditation[]) => ({
  payload: { meditations },
}));

export const updateMoments = createAction('update-moments', (moments: Moment[]) => ({ payload: { moments } }));
