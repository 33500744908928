import { createSelector } from 'reselect';
import { CombinedState } from '@reduxjs/toolkit';
import { State as RootState } from 'models/redux-state';
import { NAME } from './constants';
import { State } from './models';

export const getUidProp = (_, { uid }: { uid: string }) => uid;

export const getState = (state: CombinedState<RootState>): State => state[NAME];

export const getUser = createSelector(getState, (state) => state.user);

export const getStatistics = createSelector(getState, (state) => state.statistics);

export const getWeekStreak = createSelector(getStatistics, (statistics) => statistics.weekMeditationStreak);

export const getFavoriteMeditations = createSelector(
  getState,
  getUser,
  (state, user) => state.favoriteMeditations[user?.uid],
);

export const getFavoriteMusicTracks = createSelector(
  getState,
  getUser,
  (state, user) => state.favoriteMusicTracks[user?.uid],
);

export const getRecentlyPlayedMeditations = createSelector(getState, getUser, (state, user) =>
  [...(state.recentlyPlayedMeditations[user?.uid] ?? [])].sort(({ date: dateA }, { date: dateB }) =>
    new Date(dateA).toISOString() > new Date(dateB).toISOString() ? 1 : -1,
  ),
);

export const getRecentlyPlayedMusicTracks = createSelector(getState, getUser, (state, user) =>
  [...(state.recentlyPlayedMusicTracks[user?.uid] ?? [])].sort(({ date: dateA }, { date: dateB }) =>
    new Date(dateA).toISOString() > new Date(dateB).toISOString() ? 1 : -1,
  ),
);

export const getLocale = createSelector(getState, (state) => state.locale);

export const getTimezone = createSelector(getState, (state) => state.user?.timezone ?? state.timezone);

export const getUserTimezone = createSelector(getUser, (user) => user.timezone);

export const getTimezones = createSelector(getState, (state) => state.timezones);

export const getOffset = createSelector(getState, (state) => (state.user?.timeOffset ?? state.offset) * 1000);

export const getUserOffset = createSelector(getUser, (user) => user.timeOffset);

export const getCourseProgress = createSelector(getState, (state) => state.courseProgress);

export const getUserCourseProgress = createSelector(
  getCourseProgress,
  getUser,
  (courseProgress, user) => courseProgress[user?.uid],
);

export const getUserCourseProgressByUid = createSelector(
  getUidProp,
  getUserCourseProgress,
  (uid, courseProgress) => courseProgress?.[uid],
);
