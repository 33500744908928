import getConfig from 'utils/runtime-config';

interface ServerEnvVariables {
  NODE_ENV: string;
  HOST: string;
  BASE_URL: string;
  BEARER_TOKEN: string;
  SESSION_COOKIE: string;
  AUTHENTICATED_COOKIE: string;
  PRO_IP_API_KEY: string;
  AUTHENTICATION_TOKEN: string;
}

const { serverRuntimeConfig } = getConfig();

export default serverRuntimeConfig as ServerEnvVariables;
