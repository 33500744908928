import { css } from 'styled-components';

export const fonts = {
  primary: '"Source Sans Pro", sans-serif',
  heading: '"Montserrat", sans-serif',
};

export default css`
  html {
    font-family: ${fonts.primary};
  }
`;
