import colors from './colors';
import Global from './global';
import { fonts } from './font-families';
import typography from './typography';
import breakpointAndUp, { breakpoints, BreakpointShorthands, breakpointAndUpShorthands } from './breakpoints';
import index, { Options as IndexOptions } from './z-index';

export {
  colors,
  fonts,
  typography,
  breakpointAndUp,
  breakpoints,
  BreakpointShorthands,
  breakpointAndUpShorthands,
  index,
  IndexOptions,
};

export default Global;
