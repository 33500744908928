const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const config = {
  dsn: SENTRY_DSN,
  enabled: ['production'].includes(process.env.NODE_ENV),
  tracesSampleRate: 1,
  integrations: [],
  autoSessionTracking: false,
  environment: process.env.NODE_ENV,
};

export default config;
