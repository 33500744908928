import { createSelector } from 'reselect';
import { MusicCategory } from 'models/music-category';
import { Selectors as audioTracksSelectors } from 'domains/audio-tracks';
import { Selectors as userSelectors } from 'domains/user';
import { Selectors as sectionsSelectors } from 'domains/sections';
import { Selectors as musicSelectors } from 'domains/music';

export const getSlugProp = (_, { slug }: { slug: string }) => slug;

export const getMusicTrackTracksByUid = createSelector(
  musicSelectors.getMusicTrackByUid,
  audioTracksSelectors.getAudioTracksMap,
  (musicTrack, audioTrackMap) => musicTrack?.tracks.map((trackUid) => audioTrackMap[trackUid]) ?? [],
);

export const getLocalizedMusicTracks = createSelector(
  userSelectors.getLocale,
  musicSelectors.getMusicTracks,
  (userLocale, musicTracks) => musicTracks.filter(({ locale }) => locale === userLocale),
);

export const getLocalizedMusicTrackBySlug = createSelector(getSlugProp, getLocalizedMusicTracks, (slug, musicTracks) =>
  musicTracks.find((musicTrack) => musicTrack.slug === slug),
);

export const getLocalizedMusicCategoriesMap = createSelector(
  userSelectors.getLocale,
  sectionsSelectors.getMusicCategories,
  musicSelectors.getCategoriesMap,
  (locale, musicCategoriesOverview, musicCategories) =>
    musicCategoriesOverview[locale]?.reduce<Record<string, MusicCategory>>((result, musicCategoryUid) => {
      result[musicCategoryUid] = musicCategories[musicCategoryUid];
      return result;
    }, {}) ?? {},
);

export const getLocalizedMusicCategories = createSelector(
  getLocalizedMusicCategoriesMap,
  (musicCategoriesMap) => Object.values(musicCategoriesMap) ?? [],
);

export const getLocalizedOrderedMusicCategories = createSelector(getLocalizedMusicCategories, (musicCategories) =>
  musicCategories.sort((categoryA, categoryB) => (categoryA.position > categoryB.position ? 1 : -1)),
);

export const getLocalizedMusicCategoryBySlug = createSelector(
  userSelectors.getLocale,
  getSlugProp,
  musicSelectors.getCategories,
  (locale, slug, musicCategories) =>
    musicCategories.find((category) => category.slug === slug && category.locale === locale),
);

export const getLocalizedMusicCategoryMusicTracksBySlug = createSelector(
  getLocalizedMusicCategoryBySlug,
  musicSelectors.getMusicTracksMap,
  (category, musicTracksMap) =>
    category?.tracks.map((musicTrackUid) => musicTracksMap[musicTrackUid]).filter(Boolean) ?? [],
);

export const getLocalizedMusicCategoryWithMusicTracksBySlug = createSelector(
  userSelectors.getUser,
  getLocalizedMusicCategoryBySlug,
  musicSelectors.getMusicTracksMap,
  (user, musicCategory, musicTracksMap) => {
    const musicTracks = musicCategory?.tracks.map((musicTrackUid) => musicTracksMap[musicTrackUid]).filter(Boolean);

    return {
      musicCategory,
      musicTracks: user?.premium ? musicTracks : musicTracks.sort(({ premium }) => (premium ? 1 : -1)) ?? [],
    };
  },
);

export const getLocalizedNewMusicTracks = createSelector(
  userSelectors.getUser,
  userSelectors.getLocale,
  musicSelectors.getCategories,
  sectionsSelectors.getNewMusicTracks,
  musicSelectors.getMusicTracksMap,
  (user, locale, musicCategories, newMusic, musicTracksMap) => {
    const collection =
      newMusic[locale]
        ?.map((uid) => {
          const musicTrack = musicTracksMap[uid];
          if (!musicTrack) {
            return undefined;
          }

          const categories = musicCategories
            .filter(({ tracks }) => tracks.includes(musicTrack.uid))
            .map(({ uid }) => uid);
          return { ...musicTrack, categories };
        })
        .filter(Boolean) ?? [];
    return user?.premium ? collection : collection.sort(({ premium }) => (premium ? 1 : -1));
  },
);

export const getLocalizedNewMusicTracksWithMomentsLabel = createSelector(
  getLocalizedNewMusicTracks,
  musicSelectors.getCategoriesMap,
  (musicTracks, momentsMap) =>
    musicTracks.map((musicTrack) => ({
      ...musicTrack,
      label: musicTrack.categories
        ?.map((uid) => momentsMap[uid]?.title)
        .filter(Boolean)
        .join(' • '),
    })),
);

export const getLocalizedTrendingMusicTracks = createSelector(
  userSelectors.getUser,
  userSelectors.getLocale,
  musicSelectors.getCategories,
  sectionsSelectors.getTrendingMusicTracks,
  musicSelectors.getMusicTracksMap,
  (user, locale, musicCategories, trendingMusic, musicTracksMap) => {
    const collection =
      trendingMusic[locale]
        ?.map((uid) => {
          const musicTrack = musicTracksMap[uid];
          if (!musicTrack) {
            return undefined;
          }

          const categories = musicCategories
            .filter(({ tracks }) => tracks.includes(musicTrack.uid))
            .map(({ uid }) => uid);
          return { ...musicTrack, categories };
        })
        .filter(Boolean) ?? [];
    return user?.premium ? collection : collection.sort(({ premium }) => (premium ? 1 : -1));
  },
);

export const getLocalizedTrendingMusicTracksWithMomentsLabel = createSelector(
  getLocalizedTrendingMusicTracks,
  musicSelectors.getCategoriesMap,
  (musicTracks, momentsMap) =>
    musicTracks.map((musicTrack) => ({
      ...musicTrack,
      label: musicTrack.categories
        ?.map((uid) => momentsMap[uid]?.title)
        .filter(Boolean)
        .join(' • '),
    })),
);
