import { SessionSourceTypes } from 'models/session';
import isServer from 'utils/is-server';

const SOURCE_KEY = 'source';

const backupSingleton = {};

export function storeSessionSource(source: SessionSourceTypes): void {
  if (!isServer) {
    try {
      // make sure the session-storage is available, since this is blockable.
      window.sessionStorage.getItem(SOURCE_KEY);
      window.sessionStorage.setItem(SOURCE_KEY, source);
    } catch (e) {
      // if the session-storage isn't available, we take a singleton to try to mimic the session-storage
      backupSingleton[SOURCE_KEY] = source;
    }
  }
}

export function getSessionSource(): SessionSourceTypes | undefined {
  if (!isServer) {
    try {
      return window.sessionStorage.getItem(SOURCE_KEY) as SessionSourceTypes;
    } catch (e) {
      return backupSingleton[SOURCE_KEY];
    }
  }
}
