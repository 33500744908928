import React from 'react';
import { createIntl, createIntlCache } from 'react-intl';
import { I18nContext } from './context';

export type ProviderProps = {
  locale: string;
  messages: { [key: string]: string };
  children: JSX.Element[] | JSX.Element;
  onError?: () => void;
};

export type State = {
  formatMessage: (id: string, values?: { [key: string]: string | number }) => string;
};

const Provider: React.FC<ProviderProps> = ({ locale, messages, onError, children }) => {
  const intlCache = createIntlCache();

  const intl = createIntl(
    {
      locale,
      messages,
      ...(onError ? { onError } : null),
    },
    intlCache,
  );

  const intlState: State = {
    formatMessage: (id, values) => intl.formatMessage({ id }, values),
  };

  return <I18nContext.Provider value={intlState}>{children}</I18nContext.Provider>;
};

export default Provider;
