import type { VideoJsPlayer } from 'video.js';
import { RepeatStates } from 'models/repeat-states';
import { SignedUrl } from 'models/track';

export type State = {
  playerState: PlayerStates;
  activeTrackUid?: string;
  signedUrl: SignedUrl;
  loading: boolean;
  createPlayer: (
    musicTracks: string[],
    options?: { entryMusicTrack?: string; shuffle?: boolean; category?: string; subCategory?: string },
  ) => void;
  setPlayer: (player: VideoJsPlayer) => void;
  minimizePlayer: () => void;
  maximizePlayer: () => void;
  closePlayer: () => void;
  hasPlayer: () => boolean;
  isPlaying: boolean;
  startPlaying: () => void;
  stopPlaying: () => void;
  togglePlaying: () => void;
  next: () => void;
  previous: () => void;
  repeat: RepeatStates;
  setRepeat: (repeat: RepeatStates) => void;
  shuffle: boolean;
  setShuffle: (shuffle: boolean) => void;
  getTimeLeft: () => number;
  preferredDurationIndex: number;
  setPreferredDurationIndex: (preferredIndex: number) => void;
  startTimer: (seconds: number) => void;
  stopTimer: () => void;
  pauseTimer: () => void;
  continueTimer: () => void;
  timerSecondsLeft: number;
  timerSeconds: number;
};

export enum PlayerStates {
  Open = 'open',
  Minimized = 'minimized',
  Closed = 'closed',
}
