export type Time = {
  hours: number;
  minutes: number;
};

export function isBetweenTimestamps(start: Time, end: Time, current: Time): boolean {
  return (
    (start.hours < end.hours
      ? current.hours > start.hours && current.hours < end.hours
      : current.hours > start.hours || current.hours < end.hours) ||
    (current.hours === start.hours && current.hours !== end.hours && current.minutes >= start.minutes) ||
    (current.hours === start.hours &&
      current.hours === end.hours &&
      current.minutes >= start.minutes &&
      current.minutes <= end.minutes) ||
    (current.hours === end.hours && current.hours !== start.hours && current.minutes <= end.minutes)
  );
}

export function getMinutesBetweenTimestamps(start: string, end: string): number {
  const startDate = new Date(start);
  const endDate = new Date(end);
  return Math.floor((Math.abs(endDate.getTime() - startDate.getTime()) / (1000 * 60)) % 60);
}

function normalizeNumber(number: number): string {
  const absoluteNumber = Math.floor(Math.abs(number));
  return (absoluteNumber < 10 ? '0' : '') + absoluteNumber;
}

export function dateToIso(date: Date = new Date()): string {
  const tzo = -date.getTimezoneOffset();
  const dif = tzo >= 0 ? '+' : '-';
  const year = normalizeNumber(date.getFullYear());
  const month = normalizeNumber(date.getMonth() + 1);
  const dateNumber = normalizeNumber(date.getDate());
  const hours = normalizeNumber(date.getHours());
  const minutes = normalizeNumber(date.getMinutes());
  const seconds = normalizeNumber(date.getSeconds());
  const tzHours = normalizeNumber(tzo / 60);
  const tzMinutes = normalizeNumber(tzo % 60);

  return `${year}-${month}-${dateNumber}T${hours}:${minutes}:${seconds}${dif}${tzHours}:${tzMinutes}`;
}

export function dateToDateString(date: Date = new Date()): string {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toLocaleString('en', { minimumIntegerDigits: 2 });
  const day = date.getDate().toLocaleString('en', { minimumIntegerDigits: 2 });

  return `${year}-${month}-${day}`;
}
export function getTimezoneOffset(timeZone: string, date = new Date()): number {
  const tz = date.toLocaleString('en', { timeZone, timeStyle: 'long' }).split(' ').slice(-1)[0];
  const dateString = date.toString();
  const offset = Date.parse(`${dateString} UTC`) - Date.parse(`${dateString} ${tz}`);

  return offset / 1000;
}
