import { useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getCookie } from 'cookies-next';
import Router from 'next/router';
import { DayParts } from 'models/day-parts';
import { StreakItem, StreakStates } from 'models/streak-item';
import { useBoundDispatch } from 'domains/redux/utils';
import { useIntl } from 'domains/intl';
import useTimePassed from 'utils/hooks/use-time-passed';
import env from 'utils/server-env-variables';
import { UserContext } from './context';
import * as Selectors from './selectors';
import * as Thunks from './thunks';

const useUserContext = () => useContext(UserContext);

export const usePartOfTheDay = (): DayParts => {
  const { partOfDay } = useUserContext();
  return partOfDay;
};

export const useIsDark = (): boolean => {
  const { isDark } = useUserContext();
  return isDark;
};

export const useStreak = (): StreakItem[] => {
  const { formatMessage } = useIntl();
  const statistics = useSelector(Selectors.getStatistics);
  const weekdays = useMemo(() => Array(7).fill(undefined), []);

  return useMemo(
    () =>
      weekdays.map((_, index) => {
        const meditatedDays = Object.keys(statistics?.weekMeditationStreak ?? {});
        const weekDay = meditatedDays[index];
        const date = weekDay
          ? new Date(weekDay)
          : new Date(new Date().setDate(new Date().getDate() + (index + 1 - meditatedDays.length)));

        return {
          // Does the weekday have a value? filled. Is there a weekday but without value? skipped. No weekday? empty
          state: statistics?.weekMeditationStreak?.[weekDay]
            ? StreakStates.Filled
            : weekDay
            ? StreakStates.Skipped
            : StreakStates.Empty,
          title: formatMessage(`profile.streak.weekday.${index}`),
          day: date.getDate(),
          active: meditatedDays.length === index + 1,
        };
      }),
    [statistics?.weekMeditationStreak, weekdays, formatMessage],
  );
};

export const useKeepUserAlive = (): void => {
  const [timePassed, resetTimer] = useTimePassed(30 * 60 * 1000); // 30 minutes
  const authenticated = getCookie(env.AUTHENTICATED_COOKIE);
  const fetchUser = useBoundDispatch(Thunks.fetchUser);

  useEffect(() => {
    if (timePassed && authenticated) {
      // get user
      fetchUser({ req: undefined, res: undefined });
    }
    timePassed && resetTimer();
  }, [timePassed]);

  useEffect(() => {
    Router.events.on('routeChangeComplete', resetTimer);
    Router.events.on('routeChangeError', resetTimer);

    return () => {
      Router.events.off('routeChangeStart', resetTimer);
      Router.events.off('routeChangeComplete', resetTimer);
    };
  }, [resetTimer]);
};
