import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import { getSessionSource } from 'utils/session-storage-source';
import type { VideoJsPlayer } from 'video.js';
import { SessionSourceTypes } from 'models/session';
import { RepeatStates } from 'models/repeat-states';
import { Selectors as musicSelectors } from 'domains/music';
import { useBoundDispatch, useSelectorWithProps } from 'domains/redux/utils';
import { useDispatchMusicTrack } from 'logic-domains/sessions';
import { ActionThunks as trackingThunks } from 'logic-domains/tracking';
import { dateToIso } from 'utils/date-helpers';
import { useMusicTrackSignedUrl } from 'utils/hooks/music-player';
import useTimePassed from 'utils/hooks/use-time-passed';
import shuffleArray from 'utils/shuffle-array';
import useTimer from 'utils/hooks/use-timer';
import { PlayerStates, State } from './models';
import { MusicPlayerContext } from './context';
import { useMaxSessionTime, useTrackPlaylistFinished, useTrackPlaylistStart, useTrackSessionComplete } from './hooks';

const MusicPlayer = dynamic(import('views/music-player'));

export type ProviderProps = {
  children?: React.ReactNode;
};

const Provider: React.FC<ProviderProps> = ({ children }) => {
  const { secondsLeft, startValue: totalSeconds, startTimer, stopTimer, pauseTimer, continueTimer } = useTimer();

  const playerRef = useRef<VideoJsPlayer>();
  const [musicCategoryUid, setMusicCategoryUid] = useState<string>();
  const [musicSubCategoryUid, setMusicSubCategoryUid] = useState<string>();
  const [musicTracks, setMusicTracks] = useState<string[]>([]);
  const [playerState, setPlayerState] = useState<PlayerStates>(PlayerStates.Closed);
  const [isPlaying, setIsPlaying] = useState<boolean>();
  const [repeat, setRepeat] = useState<RepeatStates>(RepeatStates.NoRepeat);
  const [shuffle, setShuffle] = useState<boolean>();
  const playlist = useMemo(() => (shuffle ? shuffleArray(musicTracks) : musicTracks), [musicTracks, shuffle]);
  const [finishedTracks, setFinishedTracks] = useState<string[]>([]);
  const [activeTrackUid, setActiveTrackUid] = useState(playlist[0]);
  const sessionSource = useMemo(() => getSessionSource(), [musicTracks]);
  const [isCompletedSession, resetTimer] = useTimePassed();
  const [signedUrl, loading, preferredDurationIndex, setPreferredDurationIndex] =
    useMusicTrackSignedUrl(activeTrackUid);
  const _musicTrack = useSelectorWithProps(musicSelectors.getMusicTrackByUid, { uid: activeTrackUid }, [
    activeTrackUid,
  ]);

  const startTime = useMemo(() => dateToIso(), [signedUrl]);
  const dispatchSessionStart = useBoundDispatch(trackingThunks.trackMusicTrackSessionStart);

  const trackSessionComplete = useTrackSessionComplete(activeTrackUid, sessionSource, musicCategoryUid);
  const trackPlaylistStart = useTrackPlaylistStart(musicTracks, sessionSource, musicCategoryUid, musicSubCategoryUid);
  const trackPlaylistFinished = useTrackPlaylistFinished(
    musicTracks,
    sessionSource,
    musicCategoryUid,
    musicSubCategoryUid,
  );

  const [dispatchMusicTrackSession, resetSessionTimer] = useDispatchMusicTrack(
    _musicTrack?.tracks?.[preferredDurationIndex] ?? _musicTrack?.tracks?.[(_musicTrack?.tracks?.length ?? 1) - 1],
    activeTrackUid,
  );

  const source = useMemo(
    () =>
      repeat === RepeatStates.Repeat || repeat === RepeatStates.RepeatOne || shuffle
        ? SessionSourceTypes.ShuffleOrRepeat
        : sessionSource,
    [repeat, sessionSource, shuffle],
  );

  const closePlayer = useCallback(() => {
    if (_musicTrack && signedUrl) {
      isCompletedSession && dispatchMusicTrackSession(source);

      const remainingTime = playerRef.current?.remainingTime() ?? 0;
      const duration = playerRef.current?.duration() ?? 0;
      const progress = Math.round(100 - (remainingTime / duration) * 100) || 0;
      trackSessionComplete(isCompletedSession, progress);
    }
    resetTimer();
    resetSessionTimer();
    setPlayerState(PlayerStates.Closed);
    playerRef.current = undefined;
  }, [
    _musicTrack,
    signedUrl,
    resetTimer,
    resetSessionTimer,
    isCompletedSession,
    dispatchMusicTrackSession,
    source,
    trackSessionComplete,
  ]);

  const { resetTimeout } = useMaxSessionTime(closePlayer);

  const createPlayer = useCallback(
    (
      musicTracks: string[],
      options?: { entryMusicTrack?: string; shuffle?: boolean; category?: string; subCategory?: string },
    ) => {
      if (_musicTrack && signedUrl) {
        isCompletedSession && dispatchMusicTrackSession(source);

        const remainingTime = playerRef.current?.remainingTime() ?? 0;
        const duration = playerRef.current?.duration() ?? 0;
        const progress = Math.round(100 - (remainingTime / duration) * 100) || 0;
        trackSessionComplete(isCompletedSession, progress);
      }

      setMusicCategoryUid(options?.category);
      setMusicSubCategoryUid(options?.subCategory);
      setMusicTracks(musicTracks);
      setFinishedTracks([]);
      setPlayerState(PlayerStates.Minimized);
      setActiveTrackUid(options?.entryMusicTrack || (options?.shuffle ? shuffleArray(musicTracks)[0] : musicTracks[0]));
      setShuffle(options?.shuffle);
      resetTimer();
      resetSessionTimer();
      resetTimeout();
    },
    [
      _musicTrack,
      signedUrl,
      resetTimer,
      resetSessionTimer,
      isCompletedSession,
      dispatchMusicTrackSession,
      source,
      trackSessionComplete,
    ],
  );

  const minimizePlayer = useCallback(() => setPlayerState(PlayerStates.Minimized), []);
  const maximizePlayer = useCallback(() => setPlayerState(PlayerStates.Open), []);

  const hasPlayer = useCallback(() => !!playerRef.current?.player(), []);
  const startPlaying = useCallback(() => playerRef.current?.player() && playerRef.current?.play(), []);
  const stopPlaying = useCallback(() => playerRef.current?.player() && playerRef.current?.pause(), []);
  const togglePlaying = useCallback(
    () => (isPlaying ? stopPlaying() : startPlaying()),
    [isPlaying, startPlaying, stopPlaying],
  );

  const next = useCallback(() => {
    if (_musicTrack && signedUrl) {
      isCompletedSession && dispatchMusicTrackSession(source);
      isCompletedSession &&
        setFinishedTracks((oldFinishedTracks) => [
          ...oldFinishedTracks.filter((uid) => uid !== _musicTrack.uid),
          _musicTrack.uid,
        ]);

      const remainingTime = playerRef.current?.remainingTime() ?? 0;
      const duration = playerRef.current?.duration() ?? 0;
      const progress = Math.round(100 - (remainingTime / duration) * 100) || 0;
      trackSessionComplete(isCompletedSession, progress);
    }

    setActiveTrackUid((_activeTrackUid) => {
      const currentTrackIndex = playlist.findIndex((uid) => uid === _activeTrackUid);
      const nextTrackIndex = currentTrackIndex >= playlist.length - 1 ? 0 : currentTrackIndex + 1;
      return playlist[nextTrackIndex];
    });

    resetTimer();
    resetSessionTimer();
  }, [
    _musicTrack,
    signedUrl,
    resetTimer,
    resetSessionTimer,
    isCompletedSession,
    dispatchMusicTrackSession,
    source,
    trackSessionComplete,
    playlist,
  ]);

  const previous = useCallback(() => {
    if (_musicTrack && signedUrl) {
      isCompletedSession && dispatchMusicTrackSession(source);
      isCompletedSession &&
        setFinishedTracks((oldFinishedTracks) => [
          ...oldFinishedTracks.filter((uid) => uid !== _musicTrack.uid),
          _musicTrack.uid,
        ]);

      const remainingTime = playerRef.current?.remainingTime() ?? 0;
      const duration = playerRef.current?.duration() ?? 0;
      const progress = Math.round(100 - (remainingTime / duration) * 100) || 0;
      trackSessionComplete(isCompletedSession, progress);
    }
    setActiveTrackUid((_activeTrackUid) => {
      const currentTrackIndex = playlist.findIndex((uid) => uid === _activeTrackUid);
      const previousTrackIndex = currentTrackIndex === 0 ? playlist.length - 1 : currentTrackIndex - 1;
      return playlist[previousTrackIndex];
    });
    resetTimer();
    resetSessionTimer();
  }, [
    _musicTrack,
    signedUrl,
    resetTimer,
    resetSessionTimer,
    isCompletedSession,
    dispatchMusicTrackSession,
    source,
    trackSessionComplete,
    playlist,
  ]);

  const _onFinish = useCallback(() => {
    if (repeat === RepeatStates.RepeatOne || (repeat === RepeatStates.Repeat && playlist.length === 1)) {
      if (_musicTrack && signedUrl) {
        isCompletedSession && dispatchMusicTrackSession(SessionSourceTypes.ShuffleOrRepeat);
        isCompletedSession &&
          setFinishedTracks((oldFinishedTracks) => [
            ...oldFinishedTracks.filter((uid) => uid !== _musicTrack.uid),
            _musicTrack.uid,
          ]);

        const remainingTime = playerRef.current?.remainingTime() ?? 0;
        const duration = playerRef.current?.duration() ?? 0;
        const progress = Math.round(100 - (remainingTime / duration) * 100) || 0;
        trackSessionComplete(isCompletedSession, progress);
      }

      resetTimer();
      resetSessionTimer();
    }

    if (repeat === RepeatStates.RepeatOne) {
      startPlaying();
      return;
    }

    if (repeat === RepeatStates.Repeat) {
      next();
      return;
    }

    if (repeat === RepeatStates.NoRepeat) {
      const currentTrackIndex = playlist.findIndex((uid) => uid === activeTrackUid);

      if (currentTrackIndex >= playlist.length - 1) {
        closePlayer();
        return;
      }

      next();
    }
  }, [
    repeat,
    playlist,
    _musicTrack,
    signedUrl,
    resetTimer,
    resetSessionTimer,
    isCompletedSession,
    dispatchMusicTrackSession,
    trackSessionComplete,
    next,
    activeTrackUid,
    closePlayer,
    startPlaying,
  ]);

  const getTimeLeft = useCallback(
    () =>
      playerRef.current?.player() && !playerRef.current.paused()
        ? playerRef.current?.duration() - playerRef.current?.currentTime()
        : 0,
    [],
  );

  const _startTimer = useCallback(
    (seconds: number) => {
      startTimer(seconds, () => {
        closePlayer();
      });
    },
    [closePlayer, startTimer],
  );

  const setPlayer = useCallback((player: VideoJsPlayer) => {
    playerRef.current = player;
    player.on('playing', () => setIsPlaying(true));
    player.on('pause', () => setIsPlaying(false));
  }, []);

  useEffect(() => {
    if (playerRef.current?.player()) {
      const ended = () => _onFinish();
      playerRef.current?.on('ended', ended);
      return () => playerRef.current?.off('ended', ended);
    }
  }, [_onFinish]);

  useEffect(() => {
    if (signedUrl?.uid) {
      dispatchSessionStart({
        musicTrackUid: _musicTrack.uid,
        trackUid: signedUrl.uid,
        source: sessionSource,
        startTime,
        musicCategoryUid,
      });
    }
    // only signedUrl to prevent double dispatches with wrong combinations of data. The signedUrl is leading
  }, [signedUrl?.uid]);

  useEffect(() => {
    if (signedUrl?.uid && !finishedTracks.length) {
      trackPlaylistStart();
    }
  }, [finishedTracks.length, signedUrl?.uid, trackPlaylistStart]);

  useEffect(() => {
    if (playlist.length && finishedTracks.length === playlist.length) {
      trackPlaylistFinished();
    }
  }, [finishedTracks.length, playlist.length, trackPlaylistFinished]);

  const _state: State = {
    playerState,
    activeTrackUid,
    signedUrl,
    loading,
    createPlayer,
    setPlayer,
    minimizePlayer,
    maximizePlayer,
    closePlayer,
    hasPlayer,
    startPlaying,
    stopPlaying,
    togglePlaying,
    next,
    previous,
    repeat,
    setRepeat,
    shuffle,
    setShuffle,
    isPlaying,
    getTimeLeft,
    preferredDurationIndex,
    setPreferredDurationIndex,
    startTimer: _startTimer,
    stopTimer,
    pauseTimer,
    continueTimer,
    timerSecondsLeft: secondsLeft,
    timerSeconds: totalSeconds,
  };

  return (
    <MusicPlayerContext.Provider value={_state}>
      {children}
      {playerState !== PlayerStates.Closed && <MusicPlayer isMinimized={playerState === PlayerStates.Minimized} />}
    </MusicPlayerContext.Provider>
  );
};

export default Provider;
