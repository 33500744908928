import { LOCALES } from 'config/constants';
import { CourseProgress } from 'models/course';
import { RecentlyPlayedMeditation, RecentlyPlayedMusicTrack } from 'models/recently-played';
import { User, UserStatistics } from 'models/user';
import createActionDomain from 'utils/create-action-domain';
import { NAME } from './constants';

const createAction = createActionDomain(NAME);

export const setUser = createAction('set-user', (user: User) => ({ payload: { user } }));

export const setStatistics = createAction('set-statistics', (statistics: UserStatistics) => ({
  payload: { statistics },
}));

export const setWeekStreak = createAction('set-week-streak', (weekStreak: Record<string, number>) => ({
  payload: { weekStreak },
}));

export const setRecentlyPlayedMeditations = createAction(
  'set-recently-played-meditations',
  (uid: string, recentlyPlayed: RecentlyPlayedMeditation[]) => ({
    payload: { uid, recentlyPlayed },
  }),
);

export const setRecentlyPlayedMusicTracks = createAction(
  'set-recently-played-music-tracks',
  (uid: string, recentlyPlayed: RecentlyPlayedMusicTrack[]) => ({
    payload: { uid, recentlyPlayed },
  }),
);

export const setFavoriteMeditations = createAction('set-favorite-meditations', (uid: string, favorites: string[]) => ({
  payload: { uid, favorites },
}));

export const setFavoriteMusicTracks = createAction('set-favorite-music-tracks', (uid: string, favorites: string[]) => ({
  payload: { uid, favorites },
}));

export const setCourseProgress = createAction('set-course-progress', (uid: string, progress: CourseProgress) => ({
  payload: { uid, progress },
}));

export const setLocale = createAction('set-locale', (locale: LOCALES) => ({ payload: { locale } }));

export const setTimezone = createAction('set-timezone', (timezone: string) => ({ payload: { timezone } }));

export const setTimezones = createAction('set-timezones', (timezones: string[]) => ({ payload: { timezones } }));

export const setUTCOffset = createAction('set-utc-offset', (offset: number) => ({ payload: { offset } }));
