import React from 'react';

export type State = ReducerState & {
  showModal: <PropTypes>(Component: React.ComponentType<PropTypes>, props?: PropTypes) => void;
  hideModal: () => void;
  showsModal?: boolean;
};

export type ReducerState = {
  component: React.FC;
  props?: Record<string, unknown>;
};

export enum Actions {
  MODAL_OPEN = 'modal-open',
  MODAL_HIDE = 'modal-hide',
}

export type Action = {
  type: Actions;
  component?: React.FC;
  props?: Record<string, unknown>;
};
