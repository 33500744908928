import { CombinedState, createAsyncThunk } from '@reduxjs/toolkit';
import { Selectors as userSelectors } from 'domains/user';
import { State as RootState } from 'models/redux-state';
import { SuperProperties } from 'services/tracking';
import { NAME } from './constants';

export const getSuperProperties = createAsyncThunk<
  SuperProperties | undefined,
  undefined,
  { state: CombinedState<RootState> }
>(`${NAME}/get-super-properties`, async (_, thunkAPI) => {
  const user = userSelectors.getUser(thunkAPI.getState());
  const locale = userSelectors.getLocale(thunkAPI.getState());
  const stats = userSelectors.getStatistics(thunkAPI.getState());

  if (user) {
    return {
      app_type: 'Web-app',
      selected_app_language: locale,
      selected_communication_language: user.language,
      premium_status: user.premiumStatus,
      total_months_subscribed: user.monthsSubscribed,
      total_minutes_meditated: stats.minutesMeditated,
      total_meditation_sessions: stats.meditationSessions,
    };
  }

  return undefined;
});
