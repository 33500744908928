import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { Meditation } from 'models/meditation';
import { Moment } from 'models/moment';
import * as Actions from './actions';
import { State } from './models';

const initialState: State = {
  moments: {},
  items: {},
};

export function onUpdateMeditations(state: State, { payload }: PayloadAction<{ meditations: Meditation[] }>): void {
  state.items = payload.meditations.reduce(
    (result, meditation) => ({ ...result, [meditation.uid]: meditation }),
    state.items,
  );
}

export function onUpdateMoments(state: State, { payload }: PayloadAction<{ moments: Moment[] }>): void {
  state.moments = payload.moments.reduce((result, moment) => ({ ...result, [moment.uid]: moment }), state.moments);
}

export default createReducer(initialState, {
  [String(Actions.updateMeditations)]: onUpdateMeditations,
  [String(Actions.updateMoments)]: onUpdateMoments,
});
