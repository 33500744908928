import { MusicCategory } from 'models/music-category';
import { MusicTrack } from 'models/music-track';
import createActionDomain from 'utils/create-action-domain';
import { NAME } from './constants';

const createAction = createActionDomain(NAME);

export const updateCategories = createAction('update-categories', (categories: MusicCategory[]) => ({
  payload: { categories },
}));

export const updateMusicTracks = createAction('update-music-tracks', (musicTracks: MusicTrack[]) => ({
  payload: { musicTracks },
}));
