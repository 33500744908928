import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Cookie } from 'models/cookie';
import { useBoundDispatch } from 'domains/redux/utils';
import * as Selectors from './selectors';
import * as Thunks from './thunks';

export function useCookie(): [cookie: Cookie, loading: boolean] {
  const [loading, setLoading] = useState<boolean>(false);
  const cookie = useSelector(Selectors.getCookie);
  const fetchCookies = useBoundDispatch(Thunks.fetchCookies);

  useEffect(() => {
    if (!cookie) {
      setLoading(true);
      fetchCookies(undefined).then(() => setLoading(false));
    }
  }, [cookie]);

  return [cookie, loading];
}
