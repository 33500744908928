import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Meditation } from 'models/meditation';
import { MusicTrack } from 'models/music-track';
import { Course } from 'models/course';
import { SessionSourceTypes } from 'models/session';
import { ActionThunks as trackingThunks } from 'logic-domains/tracking';
import { Selectors as userSelectors } from 'domains/user';
import { useBoundDispatch } from 'domains/redux/utils';
import { dateToIso } from 'utils/date-helpers';
import { useMusicTrackSignedUrl } from 'utils/hooks/music-player';
import isServer from 'utils/is-server';
import { storeSessionSource } from 'utils/session-storage-source';
import { State } from './models';
import { MusicPlayerContext } from './context';

export const useMusicPlayer = (): State => {
  return useContext(MusicPlayerContext);
};

export const useOnTrack = (
  tracks: (Course | Meditation | MusicTrack)[],
  session: SessionSourceTypes,
): ((uid: string) => void) => {
  const { createPlayer } = useMusicPlayer();
  const user = useSelector(userSelectors.getUser);

  return useCallback(
    (uid: string) => {
      const filtered = tracks.filter(({ type }) => type === 'musicTrack') as MusicTrack[];

      if (!user?.premium && filtered.find(({ uid: trackUid }) => trackUid === uid)?.premium) {
        createPlayer([uid]);
        return;
      }

      const _tracks = user?.premium ? filtered : filtered.filter(({ premium }) => !premium);
      storeSessionSource(session);
      createPlayer(
        _tracks.map(({ uid }) => uid),
        { entryMusicTrack: uid },
      );
    },
    [user, createPlayer, tracks, session],
  );
};

export const useTrackSessionComplete = (musicTrackUid: string, source: string, musicCategoryUid?: string) => {
  const [signedUrl] = useMusicTrackSignedUrl(musicTrackUid);
  const startTime = useMemo(() => dateToIso(), [signedUrl]);
  const dispatchSessionCanceled = useBoundDispatch(trackingThunks.trackMusicTrackSessionCanceled);
  const dispatchSessionFinished = useBoundDispatch(trackingThunks.trackMusicTrackSessionFinished);

  return useCallback(
    (isCompletedSession: boolean, progress?: number) => {
      const session = {
        musicTrackUid,
        trackUid: signedUrl.uid,
        musicCategoryUid,
        source,
        startTime,
        endTime: dateToIso(),
      };

      if (isCompletedSession) {
        dispatchSessionFinished({
          ...session,
          progress,
        });
        return;
      }

      dispatchSessionCanceled(session);
    },
    [
      musicTrackUid,
      dispatchSessionCanceled,
      dispatchSessionFinished,
      musicCategoryUid,
      source,
      signedUrl?.uid,
      startTime,
    ],
  );
};

export const useTrackPlaylistStart = (
  tracks: string[],
  source: SessionSourceTypes,
  musicCategoryUid?: string,
  musicSubcategoryUid?: string,
) => {
  const [tracked, setTracked] = useState<boolean>(false);
  const dispatchPlaylistStart = useBoundDispatch(trackingThunks.trackMusicPlaylistStarted);
  const dispatchCategoryPlaylistStart = useBoundDispatch(trackingThunks.trackMusicCategoryPlaylistStarted);

  useEffect(() => {
    // reset tracked when there are new tracks, since the user has selected a new playlist.
    setTracked(false);
  }, [tracks]);

  return useCallback(() => {
    if (!tracked) {
      const track = musicCategoryUid ? dispatchCategoryPlaylistStart : dispatchPlaylistStart;
      track({ source, categoryUid: musicCategoryUid, subCategoryUid: musicSubcategoryUid });
      setTracked(true);
    }
  }, [dispatchCategoryPlaylistStart, dispatchPlaylistStart, musicCategoryUid, musicSubcategoryUid, source]);
};

export const useTrackPlaylistFinished = (
  tracks: string[],
  source: SessionSourceTypes,
  musicCategoryUid?: string,
  musicSubcategoryUid?: string,
) => {
  const startDate = useMemo(() => dateToIso(), [tracks]);
  const [tracked, setTracked] = useState<boolean>(false);
  const dispatchPlaylistFinished = useBoundDispatch(trackingThunks.trackMusicPlaylistFinished);
  const dispatchCategoryPlaylistFinished = useBoundDispatch(trackingThunks.trackMusicCategoryPlaylistFinished);

  useEffect(() => {
    // reset tracked when there are new tracks, since the user has selected a new playlist.
    setTracked(false);
  }, [tracks]);

  return useCallback(() => {
    if (!tracked) {
      const startDateTime = new Date(startDate).getTime();
      const endDateTime = new Date().getTime();
      const durationSeconds = Math.floor((endDateTime - startDateTime) / 1000);
      const duration = Math.floor(durationSeconds / 60);
      const track = musicCategoryUid ? dispatchCategoryPlaylistFinished : dispatchPlaylistFinished;
      track({
        categoryUid: musicCategoryUid,
        subCategoryUid: musicSubcategoryUid,
        tracks,
        source,
        startDate,
        duration,
      });
      setTracked(true);
    }
  }, [
    tracked,
    dispatchCategoryPlaylistFinished,
    dispatchPlaylistFinished,
    musicCategoryUid,
    musicSubcategoryUid,
    source,
    startDate,
    tracks,
  ]);
};

/**
 *
 * @param milliseconds the amount of milliseconds before the callback is executed. Default is set to 10 hours
 * @param callback function to execute
 */
export const useMaxSessionTime = (
  callback: () => void,
  milliseconds = 10 * 60 * 60 * 1000,
): { resetTimeout: () => void } => {
  const timerRef = useRef<number>();
  const startTimeout = useCallback(() => {
    if (isServer) {
      return;
    }

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = window.setTimeout(() => {
      callback();
    }, milliseconds);
  }, [callback, milliseconds]);

  useEffect(() => {
    startTimeout();
  }, [startTimeout]);

  return {
    resetTimeout: startTimeout,
  };
};
