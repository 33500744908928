import mixpanel from 'mixpanel-browser';
import isServer from 'utils/is-server';
import { SuperProperties } from '../models/super-properties';
import { PageEvents, MenuPageEvents } from '../models/page-events';
import { ActionEvents } from '../models/action-events';
import { EventProps } from '../models/event-props';

export default class MixpanelService {
  private static instance: typeof mixpanel;

  get mixpanel(): typeof mixpanel {
    if (!MixpanelService.instance) {
      MixpanelService.instance = mixpanel;
    }

    return MixpanelService.instance;
  }

  constructor(userId: string, superProperties?: SuperProperties) {
    if (isServer) {
      throw new Error('MixpanelService can only be used in the browser');
    }

    if (MixpanelService.instance) {
      this.mixpanel.identify(userId);
      superProperties && this.mixpanel.register(superProperties);
      return;
    }

    this.mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN, { debug: process.env.NODE_ENV === 'development' });
    this.mixpanel.identify(userId);
    superProperties && this.mixpanel.register(superProperties);
  }

  track<TrackEvent extends PageEvents | MenuPageEvents | ActionEvents>(
    trackEvent: TrackEvent,
    eventInfo?: EventProps<TrackEvent>,
  ): void {
    this.mixpanel.track(trackEvent, eventInfo);
  }
}
