export type ResponseHeaders = {
  'set-cookie'?: string | string[];
  'set-Cookie'?: string | string[];
  'Set-Cookie'?: string | string[];
  'SET-COOKIE'?: string | string[];
};

const allowedHeaders = ['set-cookie', 'set-Cookie', 'Set-Cookie', 'SET-COOKIE'];

export default function parseResponseHeaders(headers: ResponseHeaders): ResponseHeaders {
  return Object.keys(headers).reduce((result, header) => {
    if (allowedHeaders.includes(header)) {
      result[header] = headers[header];
    }

    return result;
  }, {});
}
