import { AxiosRequestConfig } from 'axios';
import { getCookie } from 'cookies-next';
import { IncomingMessage } from 'http';
import env from 'utils/server-env-variables';

const allowedHeaders = ['referer', 'x-xsrf-token', 'X-XSRF-TOKEN', 'cookie', 'Cookie'];

export function getAxiosAuthApiOptions(req?: IncomingMessage): Partial<AxiosRequestConfig> {
  const options: AxiosRequestConfig = {};

  if (req?.headers) {
    options.headers = allowedHeaders.reduce((result, headerKey) => {
      if (req.headers[headerKey]) {
        result[headerKey] = req.headers[headerKey];
      }
      return result;
    }, {});
    return options;
  }

  return {
    withCredentials: true,
  };
}

export function getAxiosAuthClientOptions(req?: IncomingMessage): Partial<AxiosRequestConfig> {
  const options: AxiosRequestConfig = {};

  if (req?.headers) {
    // since the site can de referred to by other sources we need to send the host of the site itself.
    options.headers = { referer: req.headers.host, cookie: req.headers.cookie };
    return options;
  }

  return {
    withCredentials: true,
  };
}

export function isPremiumUserRequest(req?: IncomingMessage): boolean {
  const sessionCookie = getCookie(env.SESSION_COOKIE, { req });
  return !!sessionCookie;
}
