import { useState } from 'react';
import { useRouter } from 'next/router';
import { SessionRelatedSourceTypes, SessionSourceTypes } from 'models/session';
import { useBoundDispatch, useSelectorWithProps } from 'domains/redux/utils';
import { Selectors as meditationsSelectors } from 'domains/meditations';
import { Selectors as coursesSelectors } from 'domains/courses';
import { Selectors as logicMeditationsSelectors } from 'logic-domains/meditations';
import { Selectors as programsSelectors } from 'logic-domains/programs';
import { dateToIso } from 'utils/date-helpers';
import * as Thunks from './thunks';

export function useDispatchMeditationSession(
  trackUid: string,
  meditationUid?: string,
): [dispatch: (source?: string) => Promise<void>, resetStartTime: (date?: Date) => void] {
  const router = useRouter();
  const slugMeditation = useSelectorWithProps(
    logicMeditationsSelectors.getLocalizedMeditationBySlug,
    { slug: router?.query?.slug },
    [router?.query?.slug],
  );
  const uidMeditation = useSelectorWithProps(meditationsSelectors.getMeditationByUid, { uid: meditationUid });
  const meditation = uidMeditation || slugMeditation;

  const [startTime, setStartTime] = useState(dateToIso());
  const resetStartTime = (date: Date = new Date()) => setStartTime(dateToIso(date));

  const dispatchSession = useBoundDispatch(Thunks.addSession);
  const dispatch = async (source) => {
    await dispatchSession({
      startDate: startTime,
      endDate: dateToIso(),
      source,
      audioTrackUid: trackUid,
      relatedSource: { uid: meditation.uid, type: SessionRelatedSourceTypes.Meditation },
    });
  };

  return [dispatch, resetStartTime];
}

export function useDispatchRelatedMeditationTrackSession(
  trackUid: string,
): [dispatch: (source?: string) => Promise<void>, resetStartTime: (date?: Date) => void] {
  const [startTime, setStartTime] = useState(dateToIso());
  const dispatchSession = useBoundDispatch(Thunks.addSession);
  const relatedMusicTrack = useSelectorWithProps(
    logicMeditationsSelectors.getRelatedMusicTrackByMeditationTrackUid,
    { uid: trackUid },
    [trackUid],
  );

  const resetStartTime = (date: Date = new Date()) => setStartTime(dateToIso(date));
  const dispatch = async (source = SessionSourceTypes.RelatedAudioTrack) => {
    await dispatchSession({
      startDate: startTime,
      endDate: dateToIso(),
      source,
      audioTrackUid: trackUid,
      relatedSource: { uid: relatedMusicTrack.uid, type: SessionRelatedSourceTypes.MusicTrack },
    });
  };

  return [dispatch, resetStartTime];
}

export function useDispatchProgramLesson(
  trackUid: string,
  courseUid?: string,
): [dispatch: (source?: string) => Promise<void>, resetStartTime: (date?: Date) => void] {
  const router = useRouter();
  const slugCourse = useSelectorWithProps(programsSelectors.getLocalizedProgramBySlug, { slug: router?.query?.slug }, [
    router?.query?.slug,
  ]);
  const uidCourse = useSelectorWithProps(coursesSelectors.getCourseByUid, { uid: courseUid });
  const course = uidCourse || slugCourse;
  const [startTime, setStartTime] = useState(dateToIso());
  const dispatchSession = useBoundDispatch(Thunks.addSession);

  const resetStartTime = (date: Date = new Date()) => setStartTime(dateToIso(date));
  const dispatch = async (source) => {
    await dispatchSession({
      startDate: startTime,
      endDate: dateToIso(),
      source,
      audioTrackUid: trackUid,
      relatedSource: { uid: course.uid, type: SessionRelatedSourceTypes.Meditation },
    });
  };

  return [dispatch, resetStartTime];
}

export function useDispatchMusicTrack(
  trackUid: string,
  musicTrackUid: string,
): [dispatch: (source?: string) => Promise<void>, resetStartTime: (date?: Date) => void] {
  const [startTime, setStartTime] = useState(dateToIso());
  const dispatchSession = useBoundDispatch(Thunks.addSession);

  const resetStartTime = (date: Date = new Date()) => setStartTime(dateToIso(date));
  const dispatch = async (source) => {
    await dispatchSession({
      startDate: startTime,
      endDate: dateToIso(),
      source,
      audioTrackUid: trackUid,
      relatedSource: { uid: musicTrackUid, type: SessionRelatedSourceTypes.MusicTrack },
    });
  };

  return [dispatch, resetStartTime];
}

export function useDispatchYogaSession(
  trackUid: string,
  yogaItemUid: string,
): [dispatch: (source?: string) => Promise<void>, resetStartTime: (date?: Date) => void] {
  const [startTime, setStartTime] = useState(dateToIso());
  const resetStartTime = (date: Date = new Date()) => setStartTime(dateToIso(date));

  const dispatchSession = useBoundDispatch(Thunks.addSession);
  const dispatch = async (source) => {
    await dispatchSession({
      startDate: startTime,
      endDate: dateToIso(),
      source,
      audioTrackUid: trackUid,
      relatedSource: { uid: yogaItemUid, type: SessionRelatedSourceTypes.Yoga },
    });
  };

  return [dispatch, resetStartTime];
}

export function useDispatchFlowSession(
  trackUid: string,
  flowItemUid: string,
): [dispatch: (source?: string) => Promise<void>, resetStartTime: (date?: Date) => void] {
  const [startTime, setStartTime] = useState(dateToIso());
  const resetStartTime = (date: Date = new Date()) => setStartTime(dateToIso(date));

  const dispatchSession = useBoundDispatch(Thunks.addSession);
  const dispatch = async (source) => {
    await dispatchSession({
      startDate: startTime,
      endDate: dateToIso(),
      source,
      audioTrackUid: trackUid,
      relatedSource: { uid: flowItemUid, type: SessionRelatedSourceTypes.Flow },
    });
  };

  return [dispatch, resetStartTime];
}
