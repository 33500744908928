import styled from 'styled-components';
import { index, IndexOptions } from 'assets/styles';

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${index(IndexOptions.MODAL)};
`;
