import App, { AppContext, AppProps } from 'next/app';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import { Provider as ReduxProvider } from 'react-redux';
import isMobile from 'is-mobile';
import translations from 'data/translations';
import { LOCALES } from 'config/constants';
import { useStore } from 'domains/redux';
import { Selectors as errorsSelectors } from 'domains/errors';
import { Provider as RouteProvider } from 'domains/route';
import { Provider as UserProvider, Selectors as userSelectors } from 'domains/user';
import { Provider as I18nProvider } from 'domains/intl';
import { Provider as ModalProvider } from 'domains/modal';
import { Provider as MusicPlayerProvider } from 'logic-domains/music-player';
import LoadingIndicator from 'layouts/loading-indicator';
import isServer from 'utils/is-server';
import PremiumPopupModal from 'views/modals/premium-popup';
// import SharedMusicTrack from 'views/modals/shared-music-track';
import Global from 'assets/styles';

const ErrorView = dynamic(import('views/error'));

type ExtendedAppProps = AppProps & {
  isMobile?: boolean;
};

export default function _App({ Component, pageProps, isMobile }: ExtendedAppProps): JSX.Element {
  const store = useStore(pageProps.initialState);
  const _locale = userSelectors.getLocale(store.getState());
  const errorCode = errorsSelectors.getErrorCode(store.getState());

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </Head>
      <RouteProvider>
        <ReduxProvider store={store}>
          <UserProvider>
            <I18nProvider locale={_locale} messages={translations[_locale] || translations[LOCALES.EN]}>
              <ModalProvider isMobile={isMobile} hasTrack={pageProps.hasTrack}>
                <MusicPlayerProvider>
                  <Global />
                  <LoadingIndicator />
                  {errorCode ? <ErrorView errorCode={errorCode} /> : <Component {...pageProps} />}
                  <PremiumPopupModal />
                  {/* <SharedMusicTrack /> */}
                </MusicPlayerProvider>
              </ModalProvider>
            </I18nProvider>
          </UserProvider>
        </ReduxProvider>
      </RouteProvider>
    </>
  );
}

_App.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);

  return { ...appProps, isMobile: isMobile({ ua: appContext.ctx?.req, tablet: true }) };
};

// todo: try to get this to work in NextJS instead of around it.
// issue committed to NextJS: https://github.com/vercel/next.js/issues/41059
// Fix for the bfcache provided by https://gomakethings.com/fixing-safaris-back-button-browser-cache-issue-with-vanilla-js/
if (!isServer) {
  window.addEventListener('pageshow', (e: PageTransitionEvent) => {
    if (e.persisted || window.performance?.navigation.type === 2) {
      window.location.reload();
    }
  });
}
