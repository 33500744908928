import { LOCALES } from 'config/constants';
import { AppInfo } from 'models/app-info';
import { Cookie } from 'models/cookie';
import createActionDomain from 'utils/create-action-domain';
import { NAME } from './constants';

const createAction = createActionDomain(NAME);

export const updateInfo = createAction('update-info', (locale: LOCALES, info: AppInfo) => ({
  payload: { locale, info },
}));

export const updateCount = createAction('update-count', (count: number) => ({ payload: { count } }));

export const setCookie = createAction('set-cookie', (cookie: Cookie) => ({ payload: { cookie } }));

export const setHost = createAction('set-host', (host: string) => ({ payload: { host } }));
