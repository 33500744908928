import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { LOCALES } from 'config/constants';
import { AppInfo } from 'models/app-info';
import { Cookie } from 'models/cookie';
import { State } from './models';
import * as Actions from './actions';

const initialState: State = {
  info: {},
  count: 0,
  host: undefined,
};

export function onUpdateInfo(state: State, { payload }: PayloadAction<{ locale: LOCALES; info: AppInfo }>): void {
  state.info[payload.locale] = payload.info;
}

export function onSetCookie(state: State, { payload }: PayloadAction<{ cookie: Cookie }>): void {
  state.cookie = payload.cookie;
}

export function onUpdateCounter(state: State, { payload }: PayloadAction<{ count: number }>): void {
  state.count = payload.count;
}

export function onSetHost(state: State, { payload }: PayloadAction<{ host: string }>): void {
  state.host = payload.host;
}

export default createReducer(initialState, {
  [String(Actions.updateInfo)]: onUpdateInfo,
  [String(Actions.updateCount)]: onUpdateCounter,
  [String(Actions.setCookie)]: onSetCookie,
  [String(Actions.setHost)]: onSetHost,
});
