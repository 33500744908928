import { CombinedState, createAsyncThunk } from '@reduxjs/toolkit';
import { LOCALES } from 'config/constants';
import { getClientIp } from 'request-ip';
import { IncomingMessage, ServerResponse } from 'http';
import { getCookie, setCookie } from 'cookies-next';
import { CourseProgress } from 'models/course';
import { User } from 'models/user';
import { State as RootState } from 'models/redux-state';
import Axios from 'utils/axios';
import { dateToDateString } from 'utils/date-helpers';
import { getAxiosAuthClientOptions } from 'utils/get-auth-options';
import parseResponseHeaders from 'utils/parse-response-headers';
import { NAME, USER_LOCALE } from './constants';
import { encodeUserLocaleCookie, parseUserLocaleCookie } from './utils';
import * as Actions from './actions';
import * as Selectors from './selectors';

export const fetchUser = createAsyncThunk<
  void,
  { req: IncomingMessage; res?: ServerResponse },
  { state: CombinedState<RootState> }
>(`${NAME}/fetch-user`, async ({ req, res }, thunkAPI) => {
  const options = getAxiosAuthClientOptions(req);
  const { data, headers } = await Axios.get<User>('/api/user', options);
  data && thunkAPI.dispatch(Actions.setUser(data));

  if (res) {
    const parsedHeaders = parseResponseHeaders(headers || {});
    Object.keys(parsedHeaders).forEach((header) => {
      res.setHeader(header, headers[header]);
    });
  }
});

export const updateUser = createAsyncThunk<void, Partial<User>, { state: CombinedState<RootState> }>(
  `${NAME}/update-user`,
  async (values, thunkAPI) => {
    const { data } = await Axios.post('/api/user', values);

    data && thunkAPI.dispatch(Actions.setUser(data));
  },
);

export const updateUserLocaleCookie = createAsyncThunk<void, string, { state: CombinedState<RootState> }>(
  `${NAME}/update-user-locale`,
  async (value, thunkAPI) => {
    const user = Selectors.getUser(thunkAPI.getState());
    if (user) {
      const cookie = encodeUserLocaleCookie(user.uid, value);
      setCookie(USER_LOCALE, cookie, {
        sameSite: 'strict',
        // maxAge is 5 years from now, unless the person revisits the site within a year. Then it will be updated with a year again.
        maxAge: 5 * 365 * 24 * 60 * 60,
      });
    }
  },
);

export const fetchStatistics = createAsyncThunk<void, IncomingMessage | undefined, { state: CombinedState<RootState> }>(
  `${NAME}/fetch-statistics`,
  async (req, thunkAPI) => {
    const options = getAxiosAuthClientOptions(req);
    const { data } = await Axios.get<User>('/api/user/statistics', options);

    data && thunkAPI.dispatch(Actions.setStatistics(data));
  },
);

export const redeemVoucher = createAsyncThunk<void, string>(`${NAME}/redeem-voucher`, async (voucher, thunkAPI) => {
  await Axios.post('/api/user/voucher', { voucher });
  await thunkAPI.dispatch(fetchUser({ req: undefined, res: undefined }));
});

export const increaseWeekStreak = createAsyncThunk<void, undefined, { state: CombinedState<RootState> }>(
  `${NAME}/increase-week-streak`,
  async (_, thunkAPI) => {
    const weekStreak = Selectors.getWeekStreak(thunkAPI.getState());
    const todayKey = dateToDateString();
    thunkAPI.dispatch(Actions.setWeekStreak({ ...weekStreak, [todayKey]: (weekStreak[todayKey] || 0) + 1 }));
  },
);

export const setInitialLocale = createAsyncThunk<void, string, { state: CombinedState<RootState> }>(
  `${NAME}/set-initial-locale`,
  async (locale, thunkAPI) => {
    if (locale) {
      thunkAPI.dispatch(Actions.setLocale(locale));
    }
  },
);

export const setTimezoneDataByRequest = createAsyncThunk<
  void,
  IncomingMessage | undefined,
  { state: CombinedState<RootState> }
>(`${NAME}/set-timezone-by-request`, async (request, thunkAPI) => {
  if (request) {
    const ip = process.env.NODE_ENV === 'development' ? process.env.LOCAL_IP : getClientIp(request);
    const { data } = await Axios.get<{ timezone?: string; offset?: number }>('/api/timezone', {
      params: { ip },
    });
    data.timezone && thunkAPI.dispatch(Actions.setTimezone(data.timezone));
    data.offset && thunkAPI.dispatch(Actions.setUTCOffset(data.offset));
  }
});

export const fetchTimezoneOptions = createAsyncThunk<void, undefined, { state: CombinedState<RootState> }>(
  `${NAME}/fetch-timezones`,
  async (_, thunkAPI) => {
    const { data } = await Axios.get<{ timezones: string[] }>('/api/timezone/list');

    thunkAPI.dispatch(Actions.setTimezones(data.timezones));
  },
);

export const fetchCourseProgress = createAsyncThunk<
  void,
  IncomingMessage | undefined,
  { state: CombinedState<RootState> }
>(`${NAME}/fetch-course-progress`, async (req, thunkAPI) => {
  const user = Selectors.getUser(thunkAPI.getState());
  if (user) {
    const options = getAxiosAuthClientOptions(req);
    const { data } = await Axios.get<CourseProgress>('/api/user/progress', options);
    data && thunkAPI.dispatch(Actions.setCourseProgress(user.uid, data));
  }
});

export const redirectToUserLocale = createAsyncThunk<
  void,
  { req: IncomingMessage | undefined; res: ServerResponse | undefined; locale?: LOCALES; currentUrl?: string },
  { state: CombinedState<RootState> }
>(`${NAME}/redirect-user-to-locale`, ({ req, res, locale, currentUrl }, thunkAPI) => {
  if (!res || !req) {
    return;
  }
  const user = Selectors.getUser(thunkAPI.getState());

  try {
    const cookie = getCookie(USER_LOCALE, { req });
    const cookieValue = cookie ? parseUserLocaleCookie(cookie as string) : undefined;
    if (cookieValue && cookieValue.locale === locale) {
      return;
    }

    if (cookieValue && cookieValue.userUid === user?.uid) {
      res.writeHead(307, { Location: `/${cookieValue.locale}${currentUrl}` });
      res.end();
      return;
    }
  } catch (e) {
    console.warn('Invalid cookie value', e);
  }

  if (!user?.language || user.language === locale) {
    return;
  }

  res.writeHead(307, { Location: `/${user.language}${currentUrl}` });
  res.end();
});
