import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { DayParts } from 'models/day-parts';
import { DAY_PART_START_TIMES } from 'config/constants';
import useKeyPress from 'utils/hooks/use-key-press';
import { isBetweenTimestamps } from 'utils/date-helpers';
import { UserContext } from './context';
import * as Selectors from './selectors';
import { useKeepUserAlive } from './hooks';

export type ProviderProps = {
  children?: React.ReactNode;
};

const Provider: React.FC<ProviderProps> = ({ children }) => {
  const timeOffset = useSelector(Selectors.getOffset);
  const [time, setTime] = useState(new Date().getTime());
  const [overrideIsDark, setOverrideIsDark] = useState<boolean>(undefined);

  useEffect(() => {
    const interval = window.setInterval(() => {
      setTime(new Date().getTime());
    }, 1000);

    return () => window.clearInterval(interval);
  }, []);

  const partOfDay = useMemo(() => {
    const timezoneTime = new Date(time + (timeOffset || 0));
    const timezonePartTime = { hours: timezoneTime.getUTCHours(), minutes: timezoneTime.getUTCMinutes() };

    if (isBetweenTimestamps(DAY_PART_START_TIMES.morning, DAY_PART_START_TIMES.afternoon, timezonePartTime)) {
      return DayParts.Morning;
    }
    if (isBetweenTimestamps(DAY_PART_START_TIMES.afternoon, DAY_PART_START_TIMES.evening, timezonePartTime)) {
      return DayParts.Afternoon;
    }
    if (isBetweenTimestamps(DAY_PART_START_TIMES.evening, DAY_PART_START_TIMES.morning, timezonePartTime)) {
      return DayParts.Evening;
    }
  }, [timeOffset, time]);

  useKeyPress(
    [
      ['ControlLeft', 'KeyD'],
      ['ControlRight', 'KeyD'],
    ],
    () => setOverrideIsDark((oldValue) => !oldValue),
  );
  useKeepUserAlive();

  const _state = {
    partOfDay,
    isDark: overrideIsDark === undefined ? partOfDay === DayParts.Evening : overrideIsDark,
  };

  return <UserContext.Provider value={_state}>{children}</UserContext.Provider>;
};

export default Provider;
