export enum SessionSourceTypes {
  Spotlight = 'motm',
  Moment = 'moment',
  MusicCategory = 'moment',
  Stats = 'stats',
  SearchResults = 'search_results',
  ShuffleOrRepeat = 'music_shuffle_or_repeat',
  Favorites = 'favorites',
  RecentlyPlayed = 'playlist_recents',
  RelatedAudioTrack = 'related_audio_track',
  ForYouRecent = 'for_you_recently_added',
  ForYouTrending = 'for_you_now_trending',
  ForYouThisMoment = 'for_you_for_this_moment',
  ProgramDetail = 'program_overview',
  ProgramNextLesson = 'next_lesson_card_finished_flow',
  AllYogaVideos = 'all_yoga_videos',
  AllFlowVideos = 'all_flow_videos',
}

export enum SessionRelatedSourceTypes {
  Meditation = 'meditation',
  MusicTrack = 'music_track',
  Yoga = 'yoga_item',
  Flow = 'the_flow_item',
}

export type Session = {
  audioTrackUid: string;
  startDate: string;
  endDate: string;
  timeZone: string;
  source: SessionSourceTypes;
  relatedSource: {
    type: SessionRelatedSourceTypes;
    uid: string;
  };
};

/* eslint-disable camelcase */
export type PostSession = {
  uuid: string;
  audio_track_uuid: string;
  start_date: string;
  end_date?: string;
  time_zone: string;
  source: string;
  related_source: {
    type: string;
    uuid: string;
  };
};

export type SessionRequest = { sessions: PostSession[] };
/* eslint-enable camelcase */
