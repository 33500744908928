import { configureStore } from '@reduxjs/toolkit';
import isServer from 'utils/is-server';
import env from 'utils/server-env-variables';
import rootReducer from 'config/reducers';
import { CreateStoreArgs, ThunkStore, WindowWithStore } from './models';

const GLOBAL_STORE_KEY = env.NODE_ENV === 'development' ? 'redux_store' : Symbol('redux_store');

export default function createStore({ initialState = {} }: CreateStoreArgs = {}): ThunkStore {
  // if there already is a store, we won't create another.
  if (!isServer && (window as WindowWithStore)[GLOBAL_STORE_KEY]) {
    return (window as WindowWithStore)[GLOBAL_STORE_KEY];
  }

  // create the actual store
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    preloadedState: initialState,
  });

  // when a new store is created, we set it on the window to prevent other stores from being created (on every page for instance)
  if (!isServer) {
    (window as WindowWithStore)[GLOBAL_STORE_KEY] = store;
  }

  return store;
}
