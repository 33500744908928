import { ReferenceArgumentOptions } from './models';

export function parseUrlParams(url: string): Record<string, string> {
  const splitUrl = url?.split('?');
  if (splitUrl.length <= 1) return {};
  const querystring = `?${splitUrl[splitUrl.length - 1]}`;
  const paramsObject = new URLSearchParams(querystring);
  return Object.fromEntries(paramsObject);
}

export function setUrlParams(url: string, params: Record<string, string | string[]> = {}): string {
  const hasQueryParams = url?.split('?').length > 1;
  return Object.entries(params).reduce(
    (result, [key, value], index) =>
      value ? `${result}${!index && !hasQueryParams ? '?' : '&'}${key}=${value}` : result,
    url,
  );
}

export function getReferenceArguments(query: Record<string, string | string[]>): Record<string, string | string[]> {
  return Object.values(ReferenceArgumentOptions)
    .filter((option) => query[option])
    .reduce((result, option) => {
      result[option] = query[option];
      return result;
    }, {});
}
