import { createSelector } from 'reselect';
import { CombinedState } from 'redux';
import { State as RootState } from 'models/redux-state';
import { State } from './models';
import { NAME } from './constants';

const getUidProp = (_, { uid }: { uid: string }) => uid;
const getIdProp = (_, { id }: { id?: number }) => id;

export const getState = (state: CombinedState<RootState>): State => state[NAME];

export const getAudioTracksMap = createSelector(getState, (state) => state.items);
export const getAudioTracks = createSelector(getAudioTracksMap, (audioTracks) => Object.values(audioTracks));
export const getAudioTrackByUid = createSelector(getAudioTracksMap, getUidProp, (audioTracks, uid) => audioTracks[uid]);

export const getSignedUrlsMap = createSelector(getState, (state) => state.signedUrls);
export const getSignedUrls = createSelector(getSignedUrlsMap, (signedUrlsMap) => Object.values(signedUrlsMap));
export const getSignedUrlByUid = createSelector(getUidProp, getSignedUrlsMap, (uid, signedUrlMap) => signedUrlMap[uid]);

export const getNarratorsMap = createSelector(getState, (state) => state.narrators);
export const getNarrators = createSelector(getNarratorsMap, (narrators) => Object.values(narrators));
export const getNarratorById = createSelector(getNarratorsMap, getIdProp, (narrators, id) => narrators[id]);
