import { useEffect, useRef } from 'react';

const useKeyPress = (
  keys: string | string[] | string[][],
  callback: (keys: string | string[] | string[][]) => void,
): void => {
  const pressed = useRef<string[]>([]);

  useEffect(() => {
    const onKeydown = (e: KeyboardEvent) => {
      if (pressed.current.find((key) => key === e.code)) {
        return;
      }

      pressed.current.push(e.code);

      // string
      if (typeof keys === 'string') {
        pressed.current.length === 1 && pressed.current[0] === keys && callback(keys);
        return;
      }

      // string[][]
      if (Array.isArray(keys) && keys.length && Array.isArray(keys[0])) {
        const keysPressed = keys.map((combinedKeys) => combinedKeys.map((key) => pressed.current.includes(key)));
        const allKeysPressed = keysPressed.find((pressedKeys) => pressedKeys.every(Boolean));

        if (allKeysPressed && allKeysPressed.length === pressed.current.length) {
          callback(keys);
        }
        return;
      }

      // string[]
      const keysPressed = keys.map((key) => pressed.current.includes(key));

      if (pressed.current.length === keys.length && keysPressed.every(Boolean)) {
        callback(keys);
      }
    };
    const onKeyup = (e: KeyboardEvent) => (pressed.current = pressed.current.filter((code) => code !== e.code));

    document.addEventListener('keydown', onKeydown);
    document.addEventListener('keyup', onKeyup);

    return () => {
      document.removeEventListener('keydown', onKeydown);
      document.removeEventListener('keyup', onKeyup);
    };
  }, [keys]);
};

export default useKeyPress;
