import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { ReferenceArgumentOptions, ReferenceArguments } from './models';
import { RouteContext } from './context';
import { getReferenceArguments } from './utils';

export type ProviderProps = {
  children?: React.ReactNode;
};

const initialReferenceArguments: ReferenceArguments = {
  [ReferenceArgumentOptions.Origin]: 'app',
};

const Provider: React.FC<ProviderProps> = ({ children }) => {
  const [history, setHistory] = useState<string[]>([]);
  const [queryParams, setQueryParams] = useState<ReferenceArguments>(initialReferenceArguments);
  const { asPath, query } = useRouter();

  useEffect(() => setHistory((_history) => [..._history, asPath]), [asPath]);

  useEffect(() => setQueryParams((oldValue) => ({ ...oldValue, ...getReferenceArguments(query) })), []);

  const _state = {
    history,
    referenceArguments: queryParams,
  };

  return <RouteContext.Provider value={_state}>{children}</RouteContext.Provider>;
};

export default Provider;
