import { LOCALES } from 'config/constants';
import { Collection, SpotlightCollection } from 'models/collection';
import createActionDomain from 'utils/create-action-domain';
import { NAME } from './constants';

const createAction = createActionDomain(NAME);

export const updatePrograms = createAction('update-programs', (locale: LOCALES, programs: string[]) => ({
  payload: { locale, programs },
}));

export const updateMoments = createAction('update-moments', (locale: LOCALES, moments: string[]) => ({
  payload: { locale, moments },
}));

export const updateNew = createAction('update-new', (locale: LOCALES, collections: Collection[]) => ({
  payload: { locale, collections },
}));

export const updateSpotlight = createAction(
  'update-spotlight',
  (locale: LOCALES, collections: SpotlightCollection[]) => ({
    payload: { locale, collections },
  }),
);

export const updateMoment = createAction('update-moment', (locale: LOCALES, collections: Collection[]) => ({
  payload: { locale, collections },
}));

export const updateTrending = createAction('update-trending', (locale: LOCALES, collections: Collection[]) => ({
  payload: { locale, collections },
}));

export const updateDailyQuotes = createAction('update-daily-quotes', (locale: LOCALES, quotes: string[]) => ({
  payload: { locale, quotes },
}));

export const updateNewMeditations = createAction(
  'update-new-meditations',
  (locale: LOCALES, collections: string[]) => ({
    payload: { locale, collections },
  }),
);

export const updateTrendingMeditations = createAction(
  'update-trending-meditations',
  (locale: LOCALES, collections: string[]) => ({
    payload: { locale, collections },
  }),
);

export const updateNewMusicTracks = createAction(
  'update-new-music-tracks',
  (locale: LOCALES, collections: string[]) => ({
    payload: { locale, collections },
  }),
);

export const updateTrendingMusicTracks = createAction(
  'update-trending-music-tracks',
  (locale: LOCALES, collections: string[]) => ({
    payload: { locale, collections },
  }),
);

export const updateMusicCategories = createAction(
  'update-music-categories',
  (locale: LOCALES, categories: string[]) => ({
    payload: { locale, categories },
  }),
);
