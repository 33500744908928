import { createAction, PrepareAction, ActionCreatorWithPreparedPayload } from '@reduxjs/toolkit';

/**
 * An ActionCreator... creator. This prefixes all actions with the domain entered as parameter
 * @param domain The domain prefix string you wish to add to all actions.
 * @returns createAction The @reduxjs/toolkit ActionCreator.
 */
export default function createActionDomain(
  domain: string,
): (type: string, prepareAction?: PrepareAction<unknown>) => ActionCreatorWithPreparedPayload<unknown[], unknown> {
  return (type, prepareAction) => createAction(`${domain}/${type}`, prepareAction);
}
