import { createSelector } from 'reselect';
import { Moment } from 'models/moment';
import { NarratorWithTracks } from 'models/narrator';
import { Selectors as sectionsSelectors } from 'domains/sections';
import { Selectors as userSelectors } from 'domains/user';
import { Selectors as meditationsSelectors } from 'domains/meditations';
import { Selectors as musicSelectors } from 'domains/music';
import { Selectors as audioTracksSelectors } from 'domains/audio-tracks';

const getSlugProp = (_, { slug }: { slug: string }) => slug;
const getTrackUidProp = (_, { trackUid }: { trackUid: string }) => trackUid;

export const getLocalizedMeditationBySlug = createSelector(
  getSlugProp,
  userSelectors.getLocale,
  meditationsSelectors.getMeditations,
  (slug, locale, meditations) =>
    meditations.find((meditation) => meditation.slug === slug && meditation.locale === locale),
);

export const getLocalizedMeditationTracksBySlug = createSelector(
  getLocalizedMeditationBySlug,
  audioTracksSelectors.getAudioTracksMap,
  (meditation, tracks) => meditation?.tracks?.map((uid) => tracks[uid]).filter(Boolean) ?? [],
);

export const getLocalizedMeditationTrackBySlugAndTrackUid = createSelector(
  getSlugProp,
  getTrackUidProp,
  userSelectors.getLocale,
  meditationsSelectors.getMeditations,
  audioTracksSelectors.getAudioTracksMap,
  (slug, trackUid, locale, meditations, tracks) => {
    const meditation = meditations.find((meditation) => meditation.slug === slug && meditation.locale === locale);
    const track = meditation.tracks.find((uid) => trackUid === uid);
    return track && tracks[trackUid];
  },
);

export const getLocalizedMeditationNarratorsWithTracksMapBySlug = createSelector(
  getLocalizedMeditationTracksBySlug,
  audioTracksSelectors.getNarratorsMap,
  (tracks, narratorsMap) =>
    tracks.reduce<Record<number, NarratorWithTracks>>((result, track) => {
      if (!result[track.narrator]) {
        result[track.narrator] = { ...narratorsMap[track.narrator], tracks: [] };
      }
      result[track.narrator].tracks.push(track);
      return result;
    }, {}) ?? {},
);

export const getLocalizedMomentsMap = createSelector(
  userSelectors.getLocale,
  sectionsSelectors.getMoments,
  meditationsSelectors.getMomentsMap,
  (locale, momentsOverview, moments) =>
    momentsOverview[locale]?.reduce<Record<string, Moment>>((result, momentUid) => {
      result[momentUid] = moments[momentUid];
      return result;
    }, {}) ?? {},
);

export const getLocalizedMomentBySlug = createSelector(
  userSelectors.getLocale,
  getSlugProp,
  meditationsSelectors.getMoments,
  (locale, slug, moments) => {
    return moments.find((moment) => moment.slug === slug && moment.locale === locale);
  },
);

export const getLocalizedMomentMeditationsBySlug = createSelector(
  getLocalizedMomentBySlug,
  meditationsSelectors.getMeditationsMap,
  (moment, meditationsMap) =>
    moment?.tracks.map((meditationUid) => meditationsMap[meditationUid]).filter(Boolean) ?? [],
);

export const getLocalizedMomentWithMeditationsBySlug = createSelector(
  userSelectors.getUser,
  getLocalizedMomentBySlug,
  meditationsSelectors.getMeditationsMap,
  (user, moment, meditationsMap) => {
    const meditations = moment?.tracks.map((meditationUid) => meditationsMap[meditationUid]).filter(Boolean);

    return {
      moment,
      meditations: user?.premium ? meditations : meditations.sort(({ premium }) => (premium ? 1 : -1)) ?? [],
    };
  },
);

export const getLocalizedMoments = createSelector(
  getLocalizedMomentsMap,
  (momentsMap) => Object.values(momentsMap) ?? [],
);

export const getLocalizedOrderedMoments = createSelector(getLocalizedMoments, (moments) =>
  moments.sort((momentA, momentB) => (momentA.position > momentB.position ? 1 : -1)),
);

export const getLocalizedNewMeditations = createSelector(
  userSelectors.getUser,
  userSelectors.getLocale,
  meditationsSelectors.getMoments,
  sectionsSelectors.getNewMeditations,
  meditationsSelectors.getSearchableMeditationsMap,
  (user, locale, moments, newMeditations, meditationMap) => {
    const collection =
      newMeditations[locale]
        ?.map((uid) => {
          const meditation = meditationMap[uid];
          if (!meditation) {
            return undefined;
          }

          const categories = moments.filter(({ tracks }) => tracks.includes(meditation.uid)).map(({ uid }) => uid);
          return { ...meditation, categories };
        })
        .filter(Boolean) ?? [];
    return user?.premium ? collection : collection.sort(({ premium }) => (premium ? 1 : -1));
  },
);

export const getLocalizedNewMeditationsWithMomentsLabel = createSelector(
  getLocalizedNewMeditations,
  meditationsSelectors.getMomentsMap,
  (meditations, momentsMap) =>
    meditations.map((meditation) => ({
      ...meditation,
      label: meditation.categories
        ?.map((uid) => momentsMap[uid]?.title)
        .filter(Boolean)
        .join(' • '),
    })),
);

export const getLocalizedTrendingMeditations = createSelector(
  userSelectors.getUser,
  userSelectors.getLocale,
  meditationsSelectors.getMoments,
  sectionsSelectors.getTrendingMeditations,
  meditationsSelectors.getSearchableMeditationsMap,
  (user, locale, moments, trendingMeditations, meditationMap) => {
    const collection =
      trendingMeditations[locale]
        ?.map((uid) => {
          const meditation = meditationMap[uid];
          if (!meditation) {
            return undefined;
          }

          const categories = moments.filter(({ tracks }) => tracks.includes(meditation.uid)).map(({ uid }) => uid);
          return { ...meditation, categories };
        })
        .filter(Boolean) ?? [];
    return user?.premium ? collection : collection.sort(({ premium }) => (premium ? 1 : -1));
  },
);

export const getLocalizedTrendingMeditationsWithMomentsLabel = createSelector(
  getLocalizedTrendingMeditations,
  meditationsSelectors.getMomentsMap,
  (meditations, momentsMap) =>
    meditations.map((meditation) => ({
      ...meditation,
      label: meditation.categories
        ?.map((uid) => momentsMap[uid]?.title)
        .filter(Boolean)
        .join(' • '),
    })),
);

export const getRelatedMusicTrackByMeditationTrackUid = createSelector(
  audioTracksSelectors.getAudioTrackByUid,
  musicSelectors.getMusicTracksMap,
  (audioTrack, musicTracksMap) => musicTracksMap[audioTrack?.related?.musicTrackUid],
);

export const getRelatedTrackByMeditationTrackUid = createSelector(
  audioTracksSelectors.getAudioTrackByUid,
  audioTracksSelectors.getAudioTracksMap,
  (audioTrack, audioTracksMap) => audioTracksMap[audioTrack?.related?.trackUid],
);
