import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { LOCALES } from 'config/constants';
import { Collection, SpotlightCollection } from 'models/collection';
import { State } from './models';
import * as Actions from './actions';

const initialState: State = {
  programs: {},
  moments: {},
  spotlight: {},
  moment: {},
  new: {},
  trending: {},
  dailyQuotes: {},
  trendingMeditations: {},
  newMeditations: {},
  musicCategories: {},
  trendingMusic: {},
  newMusic: {},
};

export function onUpdatePrograms(
  state: State,
  { payload }: PayloadAction<{ locale: LOCALES; programs: string[] }>,
): void {
  const programsSet = new Set([...(state.programs?.[payload.locale] ?? []), ...payload.programs]);
  state.programs[payload.locale] = Array.from(programsSet);
}

export function onUpdateMoments(
  state: State,
  { payload }: PayloadAction<{ locale: LOCALES; moments: string[] }>,
): void {
  const momentsSet = new Set([...(state.moments?.[payload.locale] ?? []), ...payload.moments]);
  state.moments[payload.locale] = Array.from(momentsSet);
}

export function onUpdateSpotlight(
  state: State,
  { payload }: PayloadAction<{ locale: LOCALES; collections: SpotlightCollection[] }>,
): void {
  state.spotlight[payload.locale] = payload.collections;
}

export function onUpdateMoment(
  state: State,
  { payload }: PayloadAction<{ locale: LOCALES; collections: Collection[] }>,
): void {
  state.moment[payload.locale] = payload.collections;
}

export function onUpdateNew(
  state: State,
  { payload }: PayloadAction<{ locale: LOCALES; collections: Collection[] }>,
): void {
  state.new[payload.locale] = payload.collections;
}

export function onUpdateTrending(
  state: State,
  { payload }: PayloadAction<{ locale: LOCALES; collections: Collection[] }>,
): void {
  state.trending[payload.locale] = payload.collections;
}

export function onUpdateDailyQuotes(
  state: State,
  { payload }: PayloadAction<{ locale: LOCALES; quotes: string[] }>,
): void {
  state.dailyQuotes[payload.locale] = payload.quotes;
}

export function onUpdateNewMeditations(
  state: State,
  { payload }: PayloadAction<{ locale: LOCALES; collections: string[] }>,
): void {
  const newSet = new Set([...(state.newMeditations?.[payload.locale] ?? []), ...payload.collections]);
  state.newMeditations[payload.locale] = Array.from(newSet);
}

export function onUpdateTrendingMeditations(
  state: State,
  { payload }: PayloadAction<{ locale: LOCALES; collections: string[] }>,
): void {
  const trendingSet = new Set([...(state.trendingMeditations?.[payload.locale] ?? []), ...payload.collections]);
  state.trendingMeditations[payload.locale] = Array.from(trendingSet);
}

export function onUpdateNewMusicTracks(
  state: State,
  { payload }: PayloadAction<{ locale: LOCALES; collections: string[] }>,
): void {
  const newSet = new Set([...(state.newMusic?.[payload.locale] ?? []), ...payload.collections]);
  state.newMusic[payload.locale] = Array.from(newSet);
}

export function onUpdateTrendingMusicTracks(
  state: State,
  { payload }: PayloadAction<{ locale: LOCALES; collections: string[] }>,
): void {
  const trendingSet = new Set([...(state.trendingMusic?.[payload.locale] ?? []), ...payload.collections]);
  state.trendingMusic[payload.locale] = Array.from(trendingSet);
}

export function onUpdateMusicCategories(
  state: State,
  { payload }: PayloadAction<{ locale: LOCALES; categories: string[] }>,
): void {
  const categoriesSet = new Set([...(state.musicCategories?.[payload.locale] ?? []), ...payload.categories]);
  state.musicCategories[payload.locale] = Array.from(categoriesSet);
}

export default createReducer(initialState, {
  [String(Actions.updatePrograms)]: onUpdatePrograms,
  [String(Actions.updateMoments)]: onUpdateMoments,
  [String(Actions.updateSpotlight)]: onUpdateSpotlight,
  [String(Actions.updateMoment)]: onUpdateMoment,
  [String(Actions.updateNew)]: onUpdateNew,
  [String(Actions.updateTrending)]: onUpdateTrending,
  [String(Actions.updateDailyQuotes)]: onUpdateDailyQuotes,
  [String(Actions.updateNewMeditations)]: onUpdateNewMeditations,
  [String(Actions.updateTrendingMeditations)]: onUpdateTrendingMeditations,
  [String(Actions.updateNewMusicTracks)]: onUpdateNewMusicTracks,
  [String(Actions.updateTrendingMusicTracks)]: onUpdateTrendingMusicTracks,
  [String(Actions.updateMusicCategories)]: onUpdateMusicCategories,
});
