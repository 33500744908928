import { CombinedState, createAsyncThunk } from '@reduxjs/toolkit';
import { State as RootState } from 'models/redux-state';
import { Session, SessionSourceTypes } from 'models/session';
import { Selectors as userSelectors } from 'domains/user';
import Axios from 'utils/axios';
import { dateToIso } from 'utils/date-helpers';
import { getSessionSource } from 'utils/session-storage-source';
import { NAME } from './constants';

export const addSession = createAsyncThunk<void, Partial<Session>, { state: CombinedState<RootState> }>(
  `${NAME}/add-session`,
  async (sessionAttributes, thunkAPI) => {
    const user = userSelectors.getUser(thunkAPI.getState());

    if (user) {
      const timezone = userSelectors.getTimezone(thunkAPI.getState());
      sessionAttributes.endDate = sessionAttributes.endDate || dateToIso();
      sessionAttributes.timeZone = sessionAttributes.timeZone || timezone;
      sessionAttributes.source = sessionAttributes.source || (getSessionSource() as SessionSourceTypes);

      await Axios.post('/api/audio/session', { ...sessionAttributes });
    }
  },
);
