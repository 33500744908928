import { createSelector } from 'reselect';
import { CombinedState } from '@reduxjs/toolkit';
import { State as RootState } from 'models/redux-state';
import { State } from './models';
import { NAME } from './constants';

const getUidProp = (_, { uid }: { uid: string }) => uid;

export const getCoursesMap = (state: CombinedState<RootState>): State => state[NAME];

export const getCourses = createSelector(getCoursesMap, (courses) => Object.values(courses));

export const getCoursesOrdered = createSelector(getCourses, (courses) =>
  courses.sort((courseA, courseB) => (courseA.position > courseB.position ? 1 : -1)),
);

export const getCourseByUid = createSelector(getCoursesMap, getUidProp, (courses, uid) => courses[uid]);

export const getCourseByTrackUid = createSelector(getUidProp, getCourses, (trackUid, courses) =>
  courses.find(({ tracks }) => tracks.find(({ uid }) => trackUid === uid)),
);
