import { useCallback } from 'react';
import { AsyncThunk, CombinedState } from '@reduxjs/toolkit';
import { State as RootState } from 'models/redux-state';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkStore } from '../models';

// Because our thunks are async and useDispatch doesn't allow that, we need to pass this type
// See: https://react-redux.js.org/using-react-redux/usage-with-typescript#typing-the-usedispatch-hook
type AppDispatch = ThunkStore['dispatch'];

// eslint-disable-next-line @typescript-eslint/ban-types
export const useSelectorWithProps = <TState = {}, Props = Record<string, unknown>, TSelected = unknown>(
  selector: (state: TState, props: Props) => TSelected,
  props: Props,
  inputs: unknown[] = [],
  equalityFn?: (left: any, right: any) => boolean,
): TSelected => {
  const baseSelector = useCallback((state: TState) => selector(state, props), inputs);
  return useSelector<TState, TSelected>(baseSelector, equalityFn);
};

export const useBoundDispatch = <Input, Output>(
  actionCreator: AsyncThunk<Output, Input, { state: CombinedState<RootState> }>,
): ((input: Input) => Promise<ReturnType<AppDispatch>>) => {
  const doDispatch = useDispatch<AppDispatch>();

  // This prevents re-renders
  return useCallback(
    async (input: Input) => doDispatch(actionCreator(input)),
    [actionCreator], // We cannot add doDispatch because this can trigger an unnecessary re-render
  );
};
