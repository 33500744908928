import { createSelector } from 'reselect';
import { CombinedState } from 'redux';
import { State as RootState } from 'models/redux-state';
import { State } from './models';
import { NAME } from './constants';

export const getState = (state: CombinedState<RootState>): State => state[NAME];

export const getAppInfo = createSelector(getState, (state) => state.info);

export const getCount = createSelector(getState, (state) => state.count);

export const getCookie = createSelector(getState, (state) => state.cookie);

export const getHost = createSelector(getState, (state) => state.host);
