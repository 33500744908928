import { LOCALES } from 'config/constants';
import { SearchTerm, SearchTermContent } from 'models/search';
import createActionDomain from 'utils/create-action-domain';
import { NAME } from './constants';

const createAction = createActionDomain(NAME);

export const updateSearchTerms = createAction('update-search-terms', (searchTerms: SearchTerm[]) => ({
  payload: { searchTerms },
}));

export const addSearch = createAction(
  'add-search',
  (
    locale: LOCALES,
    searchString: string,
    suggestions: { terms: number[]; meditations: string[]; musicTracks: string[]; courses: string[] },
  ) => ({
    payload: { locale, searchString, suggestions },
  }),
);

export const updatePopularTerms = createAction('update-popular-terms', (locale: LOCALES, popularTerms: number[]) => ({
  payload: { locale, popularTerms },
}));

export const setSearchTermContent = createAction(
  'set-search-term-content',
  (id: number, content: SearchTermContent) => ({
    payload: { id, content },
  }),
);
