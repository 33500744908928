import { Quote } from 'models/quote';
import { QuoteCategory } from 'models/quote-category';
import createActionDomain from 'utils/create-action-domain';
import { NAME } from './constants';

const createAction = createActionDomain(NAME);

export const updateCategories = createAction('update-categories', (categories: QuoteCategory[]) => ({
  payload: { categories },
}));

export const updateQuotes = createAction('update-quotes', (quotes: Quote[]) => ({ payload: { quotes } }));
