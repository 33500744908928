import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import * as Actions from './actions';
import { State } from './models';

const initialState: State = {
  errorCode: undefined,
};

export function onSetErrorCode(state: State, { payload }: PayloadAction<{ errorCode: number }>): void {
  state.errorCode = payload.errorCode;
}

export default createReducer(initialState, {
  [String(Actions.setErrorCode)]: onSetErrorCode,
});
