import React, { ComponentProps, useCallback, useMemo, useReducer, useState } from 'react';
import dynamic from 'next/dynamic';
import { Action, Actions, State, ReducerState } from './models';
import { ModalContext } from './context';
import { ModalContainer } from './modal.style';

const MobileSmartBanner = dynamic(import('views/mobile-smart-banner'));

const reducer = (state: ReducerState, { type, component, props }: Action): ReducerState => {
  switch (type) {
    case Actions.MODAL_OPEN:
      return { ...state, component, props: props || {} };
    case Actions.MODAL_HIDE:
      return { ...state, component: null, props: {} };
  }
  return state;
};

export type ProviderProps = ComponentProps<'div'> & {
  isMobile?: boolean;
  hasTrack?: boolean;
};

const Provider: React.FC<ProviderProps> = ({ children, isMobile, hasTrack }) => {
  const [openSmartBanner, setOpenSmartBanner] = useState(true);
  const initialState: ReducerState = {
    component: null,
    props: {},
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const showModal = useCallback(
    (component, props = {}) => {
      dispatch({ type: Actions.MODAL_OPEN, component, props });
    },
    [dispatch],
  );

  const hideModal = useCallback(() => {
    dispatch({ type: Actions.MODAL_HIDE });
  }, [dispatch]);

  const _state = useMemo<State>(
    () => ({
      ...state,
      showModal,
      hideModal,
      showsModal: !!state.component,
    }),
    [state, showModal, hideModal],
  );

  const Component = _state.component;

  const closeSmartBanner = () => setOpenSmartBanner(false);

  return (
    <ModalContext.Provider value={_state}>
      {children}
      {isMobile && openSmartBanner && <MobileSmartBanner onClose={closeSmartBanner} hasTrack={hasTrack} />}
      {(!isMobile || !openSmartBanner) && _state.component && (
        <ModalContainer>
          <Component {..._state.props} />
        </ModalContainer>
      )}
    </ModalContext.Provider>
  );
};

export default Provider;
