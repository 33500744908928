import { IncomingMessage } from 'http';

export function getCurrentUrl({
  req,
  locale,
  asPath,
}: {
  req?: IncomingMessage;
  locale?: string;
  asPath?: string;
}): string {
  return req
    ? `${req.headers['x-forwarded-proto']}://${req.headers.host}${locale ? `/${locale}` : ''}${req.url}`
    : `${window.location.origin}${asPath}`;
}
