import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { PREMIUM_MODAL_VISITS } from 'config/constants';
import { useIsDark, Selectors as userSelectors } from 'domains/user';
import { useCookie } from 'domains/app';
import { useModal } from 'domains/modal';
import { Selectors as AppSelectors } from 'logic-domains/app';
import { useSelector } from 'react-redux';
const DiscountModal = dynamic(import('./discount'));

const PremiumPopup: React.FC = () => {
  const [isShown, setIsShown] = useState<boolean>(false);
  const router = useRouter();
  const { showModal } = useModal();
  const [cookie] = useCookie();
  const user = useSelector(userSelectors.getUser);
  const isDark = useIsDark();
  const {
    TryPremiumPopupTitle,
    TryPremiumPopupSubTitle,
    TryPremiumPopupFromPrice,
    TryPremiumPopupCurrentPrice,
    TryPremiumPopupTargetUrl,
    TryPremiumPopupBtn,
    TryPremiumPopupBgUrlDarkMode,
    TryPremiumPopupBgUrlLightMode,
  } = useSelector(AppSelectors.getLocalizedAppInfo);

  useEffect(() => {
    if (
      !isShown &&
      !user?.premium &&
      cookie?.visits === PREMIUM_MODAL_VISITS &&
      !router?.query?.track && // not showing when tracks are opened
      TryPremiumPopupTitle &&
      TryPremiumPopupTargetUrl &&
      TryPremiumPopupBtn &&
      (TryPremiumPopupBgUrlDarkMode || TryPremiumPopupBgUrlLightMode)
    ) {
      showModal(DiscountModal, {
        title: TryPremiumPopupTitle,
        subTitle: TryPremiumPopupSubTitle,
        oldPrice: TryPremiumPopupFromPrice,
        price: TryPremiumPopupCurrentPrice,
        link: TryPremiumPopupTargetUrl,
        linkLabel: TryPremiumPopupBtn,
        image: isDark ? TryPremiumPopupBgUrlDarkMode : TryPremiumPopupBgUrlLightMode,
      });
      setIsShown(true);
    }
  }, [
    isShown,
    cookie,
    TryPremiumPopupTitle,
    TryPremiumPopupSubTitle,
    TryPremiumPopupFromPrice,
    TryPremiumPopupCurrentPrice,
    TryPremiumPopupTargetUrl,
    TryPremiumPopupBtn,
    TryPremiumPopupBgUrlDarkMode,
    TryPremiumPopupBgUrlLightMode,
  ]);

  return null;
};

export default PremiumPopup;
