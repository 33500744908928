import { createSelector } from 'reselect';
import { Course } from 'models/course';
import { NarratorWithTracks } from 'models/narrator';
import { Selectors as userSelectors } from 'domains/user';
import { Selectors as sectionsSelectors } from 'domains/sections';
import { Selectors as coursesSelectors } from 'domains/courses';
import { Selectors as audioTracksSelectors } from 'domains/audio-tracks';

const getSlugProp = (_, { slug }: { slug: string }) => slug;
const getUidProp = (_, { uid }: { uid: string }) => uid;

export const getLocalizedProgramsMap = createSelector(
  userSelectors.getLocale,
  coursesSelectors.getCoursesMap,
  (locale, courses) =>
    Object.keys(courses).reduce((result, courseUid) => {
      if (courses[courseUid].locale === locale) {
        result[courseUid] = courses[courseUid];
      }
      return result;
    }, {}) ?? {},
);

export const getLocalizedPrograms = createSelector(
  userSelectors.getLocale,
  coursesSelectors.getCoursesOrdered,
  (userLocale, courses) => courses?.filter(({ locale }) => locale === userLocale) ?? [],
);

export const getLocalizedSectionProgramsMap = createSelector(
  userSelectors.getLocale,
  sectionsSelectors.getPrograms,
  coursesSelectors.getCoursesMap,
  (locale, programsOverview, courses) =>
    programsOverview[locale]?.reduce<Record<string, Course>>((result, courseUid) => {
      result[courseUid] = courses[courseUid];
      return result;
    }, {}) ?? {},
);

export const getLocalizedSectionPrograms = createSelector(
  getLocalizedSectionProgramsMap,
  (programsMap) =>
    Object.values(programsMap)?.sort((programA, programB) => (programA.position > programB.position ? 1 : -1)) ?? [],
);

export const getLocalizedProgramBySlug = createSelector(
  userSelectors.getLocale,
  getSlugProp,
  coursesSelectors.getCourses,
  (locale, slug, courses) => courses.find((course) => course.slug === slug && course.locale === locale),
);

export const getLocalizedProgramTracksBySlug = createSelector(
  getLocalizedProgramBySlug,
  audioTracksSelectors.getAudioTracksMap,
  (course, audioTracksMap) => course?.tracks.map(({ uid }) => audioTracksMap[uid]).filter(Boolean) ?? [],
);

export const getLocalizedProgramNarratorWithTracksMapBySlug = createSelector(
  getLocalizedProgramTracksBySlug,
  audioTracksSelectors.getNarratorsMap,
  (tracks, narratorsMap) =>
    tracks?.reduce<Record<number, NarratorWithTracks>>((result, track) => {
      if (!result[track.narrator]) {
        result[track.narrator] = { ...narratorsMap[track.narrator], tracks: [] };
      }
      result[track.narrator].tracks.push(track);
      return result;
    }, {}) ?? {},
);

export const getProgramsNarratorTracks = createSelector(
  coursesSelectors.getCourses,
  audioTracksSelectors.getAudioTracksMap,
  (courses, audioTracksMap) =>
    courses.reduce<Record<string, Record<number, string[]>>>((result, course) => {
      result[course.uid] = course.tracks.reduce<Record<number, string[]>>((_res, courseTrack) => {
        const track = audioTracksMap[courseTrack.uid];
        if (!track) {
          return _res;
        }

        if (!_res[track.narrator]) {
          _res[track.narrator] = [];
        }

        _res[track.narrator].push(courseTrack.uid);
        return _res;
      }, {});
      return result;
    }, {}),
);

export const getProgramsProgressMap = createSelector(
  userSelectors.getUserCourseProgress,
  getProgramsNarratorTracks,
  (coursesProgress, programsNarratorTracks) =>
    Object.keys(programsNarratorTracks).reduce<Record<string, { progress: number; total: number }>>(
      (result, courseUid) => {
        const courseProgress = coursesProgress?.[courseUid] ?? [];
        const narratorTracks = programsNarratorTracks[courseUid];

        const { progress, total } = Object.keys(narratorTracks).reduce(
          (_res, narratorId) => {
            const progress = narratorTracks[narratorId].filter((uid) => courseProgress.includes(uid)).length;
            if (!_res.progress || progress > _res.progress) {
              _res.progress = progress;
              _res.total = narratorTracks[narratorId].length;
            }
            return _res;
          },
          { total: 0, progress: 0 },
        );

        result[courseUid] = { progress, total };

        return result;
      },
      {},
    ),
);

export const getProgramProgressByUid = createSelector(
  getUidProp,
  getProgramsProgressMap,
  (programUid, programsProgressMap) => (programsProgressMap?.[programUid] ?? {}) as { total: number; progress: number },
);
