import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { Narrator } from 'models/narrator';
import { SignedUrl, Track } from 'models/track';
import * as Actions from './actions';
import { State } from './models';

const initialState: State = {
  items: {},
  narrators: {},
  signedUrls: {},
};

export function onUpdateTracks(state: State, { payload }: PayloadAction<{ tracks: Track[] }>): void {
  state.items = payload.tracks.reduce((result, item) => ({ ...result, [item.uid]: item }), state.items);
}

export function onUpdateNarrators(state: State, { payload }: PayloadAction<{ narrators: Narrator[] }>): void {
  state.narrators = payload.narrators.reduce(
    (result, narrator) => ({ ...result, [narrator.id]: narrator }),
    state.narrators,
  );
}

export function onUpdateSignedUrls(state: State, { payload }: PayloadAction<{ signedUrls: SignedUrl[] }>): void {
  state.signedUrls = payload.signedUrls.reduce(
    (result, signedUrl) => ({ ...result, [signedUrl.uid]: signedUrl }),
    state.signedUrls,
  );
}

export default createReducer(initialState, {
  [String(Actions.updateTracks)]: onUpdateTracks,
  [String(Actions.updateNarrators)]: onUpdateNarrators,
  [String(Actions.updateSignedUrls)]: onUpdateSignedUrls,
});
