import { Narrator } from 'models/narrator';
import { SignedUrl, Track } from 'models/track';
import createActionDomain from 'utils/create-action-domain';
import { NAME } from './constants';

const createAction = createActionDomain(NAME);

export const updateTracks = createAction('update-tracks', (tracks: Track[]) => ({ payload: { tracks } }));

export const updateNarrators = createAction('update-narrators', (narrators: Narrator[]) => ({
  payload: { narrators },
}));

export const updateSignedUrls = createAction('update-signed-urls', (signedUrls: SignedUrl[]) => ({
  payload: { signedUrls },
}));
