import { useRef, useState } from 'react';

export type useTimerReturn = {
  startValue: undefined | number;
  secondsLeft: undefined | number;
  startTimer: (seconds: number, onFinish: () => void) => void;
  stopTimer: () => void;
  continueTimer: () => void;
  pauseTimer: () => void;
  isTimerRunning: () => boolean;
};

const useTimer = (): useTimerReturn => {
  const [startValue, setStartValue] = useState<number>();
  const [secondsLeft, setSecondsLeft] = useState<number>();
  const timerRef = useRef<number>();
  const _onFinish = useRef<() => void>();

  const isTimerRunning = () => !!timerRef.current;

  const pauseTimer = () => {
    timerRef.current && window.clearInterval(timerRef.current);
    timerRef.current = undefined;
  };

  const stopTimer = () => {
    pauseTimer();
    setStartValue(undefined);
    setSecondsLeft(undefined);
  };

  const _timerInterval = () => {
    setSecondsLeft((oldValue) => {
      if (oldValue <= 0) {
        stopTimer();
        _onFinish.current?.();
      }
      return oldValue <= 0 ? 0 : oldValue - 1;
    });
  };

  const continueTimer = () => {
    timerRef.current = window.setInterval(_timerInterval, 1000);
  };

  const startTimer = (seconds: number, onFinish: () => void) => {
    pauseTimer();
    setStartValue(seconds);
    setSecondsLeft(seconds);
    _onFinish.current = onFinish;

    timerRef.current = window.setInterval(_timerInterval, 1000);
  };

  return { startValue, secondsLeft, startTimer, stopTimer, continueTimer, pauseTimer, isTimerRunning };
};

export default useTimer;
