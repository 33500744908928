import { CombinedState } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { State as RootState } from 'models/redux-state';
import { State } from './models';
import { NAME } from './constants';

export const getUidProp = (_, { uid }: { uid: string }) => uid;

export const getState = (state: CombinedState<RootState>): State => state[NAME];

export const getMusicTracksMap = createSelector(getState, (state: State) => state.items);

export const getMusicTracks = createSelector(getMusicTracksMap, (musicTracksMap) => Object.values(musicTracksMap));

export const getMusicTrackByUid = createSelector(
  getUidProp,
  getMusicTracksMap,
  (uid, musicTrackMap) => musicTrackMap[uid],
);

export const getMusicTrackByTrackUid = createSelector(getUidProp, getMusicTracks, (uid, musicTracks) =>
  musicTracks.find(({ tracks }) => tracks.includes(uid)),
);

export const getCategoriesMap = createSelector(getState, (state: State) => state.categories);

export const getCategories = createSelector(getCategoriesMap, (categoriesMap) => Object.values(categoriesMap));

export const getCategoryByUid = createSelector(getUidProp, getCategoriesMap, (uid, categoryMap) => categoryMap[uid]);

export const getCategoryByMusicTrackUid = createSelector(getUidProp, getCategories, (uid, categories) =>
  categories.find(({ tracks }) => tracks.includes(uid)),
);

export const getMusicTrackWithLabelByUid = createSelector(getMusicTrackByUid, getCategories, (musicTrack, categories) =>
  musicTrack
    ? {
        ...musicTrack,
        label: categories
          .filter(({ tracks }) => tracks.includes(musicTrack.uid))
          .map(({ title }) => title)
          .join(' • '),
      }
    : undefined,
);
