import { Selectors as appSelectors } from 'domains/app';
import { Selectors as meditationsSelectors } from 'domains/meditations';
import { Selectors as musicSelectors } from 'domains/music';
import { Selectors as sectionsSelectors } from 'domains/sections';
import { Selectors as userSelectors } from 'domains/user';
import { CollectionTypes } from 'models/collection';
import { createSelector } from 'reselect';

export const getLocalizedAppInfo = createSelector(
  userSelectors.getLocale,
  appSelectors.getAppInfo,
  (locale, app) => app[locale] || {},
);

export const getLocalizedMomentCollections = createSelector(
  userSelectors.getLocale,
  sectionsSelectors.getMoment,
  (locale, collections) => collections[locale],
);

export const getLocalizedMoment = createSelector(
  userSelectors.getUser,
  getLocalizedMomentCollections,
  meditationsSelectors.getSearchableMeditationsMap,
  musicSelectors.getMusicTracksMap,
  (user, momentCollection, meditationsMap, musicTracksMap) => {
    const collection =
      momentCollection
        ?.map(({ uid, type }) => (type === CollectionTypes.Meditation ? meditationsMap[uid] : musicTracksMap[uid]))
        .filter(Boolean) ?? [];
    return user?.premium ? collection : collection.sort(({ premium }) => (premium ? 1 : -1));
  },
);

export const getLocalizedSpotlight = createSelector(
  userSelectors.getLocale,
  sectionsSelectors.getSpotlight,
  (locale, collections) => collections[locale],
);

export const getLocalizedMomentWithLabels = createSelector(
  getLocalizedMoment,
  meditationsSelectors.getMoments,
  musicSelectors.getCategories,
  (collectionContent, moments, categories) => {
    return collectionContent.map((meditation) => {
      const labels = [...moments, ...categories]
        .filter(({ tracks }) => tracks.includes(meditation.uid))
        .map(({ title }) => title);

      return {
        ...meditation,
        label: labels.join(' • '),
      };
    });
  },
);

export const getLocalizedNewCollections = createSelector(
  userSelectors.getLocale,
  sectionsSelectors.getNew,
  (locale, collections) => collections[locale],
);

export const getLocalizedNew = createSelector(
  userSelectors.getUser,
  getLocalizedNewCollections,
  meditationsSelectors.getSearchableMeditationsMap,
  musicSelectors.getMusicTracksMap,
  (user, newCollection, meditationsMap, musicTracksMap) => {
    const collection =
      newCollection
        ?.map(({ uid, type }) => (type === CollectionTypes.Meditation ? meditationsMap[uid] : musicTracksMap[uid]))
        .filter(Boolean) ?? [];
    return user?.premium ? collection : collection.sort(({ premium }) => (premium ? 1 : -1));
  },
);

export const getLocalizedNewWithLabels = createSelector(
  getLocalizedNew,
  meditationsSelectors.getMoments,
  musicSelectors.getCategories,
  (collectionContent, moments, categories) =>
    collectionContent.map((meditation) => {
      const labels = [...moments, ...categories]
        .filter(({ tracks }) => tracks.includes(meditation.uid))
        .map(({ title }) => title);

      return {
        ...meditation,
        label: labels.join(' • '),
      };
    }),
);

export const getLocalizedTrendingCollections = createSelector(
  userSelectors.getLocale,
  sectionsSelectors.getTrending,
  (locale, collections) => collections[locale],
);

export const getLocalizedTrending = createSelector(
  userSelectors.getUser,
  getLocalizedTrendingCollections,
  meditationsSelectors.getSearchableMeditationsMap,
  musicSelectors.getMusicTracksMap,
  (user, trendingCollection, meditationsMap, musicTracksMap) => {
    const collection =
      trendingCollection
        ?.map(({ uid, type }) => (type === CollectionTypes.Meditation ? meditationsMap[uid] : musicTracksMap[uid]))
        .filter(Boolean) ?? [];
    return user?.premium ? collection : collection.sort(({ premium }) => (premium ? 1 : -1));
  },
);

export const getLocalizedTrendingWithLabels = createSelector(
  getLocalizedTrending,
  meditationsSelectors.getMoments,
  musicSelectors.getCategories,
  (collectionContent, moments, categories) =>
    collectionContent.map((meditation) => {
      const labels = [...moments, ...categories]
        .filter(({ tracks }) => tracks.includes(meditation.uid))
        .map(({ title }) => title);

      return {
        ...meditation,
        label: labels.join(' • '),
      };
    }),
);
