const colors = {
  tint: '#f98849',
  blue: '#2E5965',
  darkBlue: '#11404E',
  purple: '#4D5377',
  text: '#060A20',
  darkGrey: '#282B3E',
  greyOne: '#6A6E83',
  greyTwo: '#A6A8B5',
  lightGrey: '#F7F6F5',
  dividerLM: '#EDEDEF',
  dividerDM: '#515465',
  lightOrange: '#FFF7EE',
  green: '#22AD6A',
  brown: '#B3A99D',
  lightBrown: '#F9F5F2',
  darkBrown: '#6B2E10',
  white: '#FFFFFF',
  black: '#000000',
  error: '#D52020',

  shadow: '#46180A',

  gradientBrown: '#43250F',
};

export default colors;
