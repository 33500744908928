export type State = {
  history: string[];
  referenceArguments?: ReferenceArguments;
};

export enum ReferenceArgumentOptions {
  So = 'so',
  Ca = 'ca',
  Adg = 'adg',
  Cr = 'cr',
  CTag = 'c_tag',
  RefTag = 'ref_tag',
  Origin = 'orig',
}

export type ReferenceArguments = Partial<Record<ReferenceArgumentOptions, string | string[]>>;
