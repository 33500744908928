import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import fontFamilies from './font-families';

export default createGlobalStyle`
  ${reset};
  ${fontFamilies};

  html {
    font-size: 62.5%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;
