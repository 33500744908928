export enum MenuLinks {
  ForYou = '/',
  ForYouThisMoment = '/this-moment',
  ForYouNew = '/new',
  ForYouTrending = '/trending',
  Meditations = '/meditations',
  MeditationTrending = '/meditations/trending',
  MeditationNew = '/meditations/new',
  Moments = '/moments',
  Music = '/music',
  MusicCategories = '/music/categories',
  MusicTrending = '/music/trending',
  MusicNew = '/music/new',
  Programs = '/programs',
  Yoga = '/yoga',
  Flow = '/flow',
  Learning = '/learning',
  Favorites = '/favorites',
  FavoritesMusic = '/favorites/music',
  Profile = '/profile',
  ProfileEdit = '/profile/edit',
  ProfileRecentlyPlayed = '/profile/recent',
  ProfileRecentlyPlayedMusic = '/profile/recent/music',
  ProfileLanguage = '/profile/language',
  ProfileTimeZone = '/profile/time-zone',
  ProfileGiftVoucher = '/profile/gift-voucher',
  DailyInspirationNL = 'https://www.quotevandedag.com',
  DailyInspirationEN = 'https://www.dailyquotemoment.com',
}
