import { CombinedState } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { State as RootState } from 'models/redux-state';
import { State } from './models';
import { NAME } from './constants';

export const getUidProp = (_, { uid }: { uid: string }) => uid;

export const getState = (state: CombinedState<RootState>): State => state[NAME];

export const getCategoriesMap = createSelector(getState, (state) => state.categories);

export const getCategories = createSelector(getCategoriesMap, (categoriesMap) => Object.values(categoriesMap));

export const getCategoryByUid = createSelector(
  getUidProp,
  getCategoriesMap,
  (uid, categoriesMap) => categoriesMap[uid],
);

export const getQuotesMap = createSelector(getState, (state) => state.items);

export const getQuotes = createSelector(getQuotesMap, (quotesMap) => Object.values(quotesMap));

export const getQuoteByUid = createSelector(getUidProp, getQuotesMap, (uid, quotesMap) => quotesMap[uid]);

export const getQuotesByCategoryUid = createSelector(
  getCategoryByUid,
  getQuotesMap,
  (category, quotesMap) => category?.quotes?.map((quoteUid) => quotesMap[quoteUid]) ?? [],
);

export const getRandomQuoteByCategoryUid = createSelector(
  getQuotesByCategoryUid,
  (quotes) => quotes[Math.floor(Math.random() * quotes.length)],
);
