import { CombinedState, createAsyncThunk } from '@reduxjs/toolkit';
import { Selectors as audioTracksSelectors } from 'domains/audio-tracks';
import { Selectors as courseSelectors } from 'domains/courses';
import { Selectors as meditationSelectors } from 'domains/meditations';
import { Selectors as musicSelectors } from 'domains/music';
import { Selectors as yogaSelectors } from 'domains/yoga';
import { Selectors as flowSelectors } from 'domains/flow';
import { Selectors as QuoteSelectors } from 'domains/quotes';
import { Selectors as userSelectors } from 'domains/user';
import { State as RootState } from 'models/redux-state';
import { SessionSourceTypes } from 'models/session';
import { SharePlatforms } from 'models/share-platforms';
import {
  ActionEvents,
  LeanplumService,
  MixpanelService,
  PlayerTypes,
  ShareTypes,
  SuperProperties,
  ContentTypes,
} from 'services/tracking';
import { EventProps } from 'services/tracking/models/event-props';
import { dateToIso, getMinutesBetweenTimestamps } from 'utils/date-helpers';
import { getSessionSource } from 'utils/session-storage-source';
import { getSuperProperties } from './base-thunks';
import { NAME } from './constants';

export const trackAction = createAsyncThunk<
  void,
  { actionEvent: ActionEvents; eventProps: EventProps<ActionEvents> },
  { state: CombinedState<RootState> }
>(`${NAME}/track-action`, async ({ actionEvent, eventProps }, thunkAPI) => {
  if (process.env.NODE_ENV === 'development') {
    console.log('tracking - actionEvent', actionEvent, 'variables', eventProps);
  }

  const user = userSelectors.getUser(thunkAPI.getState());
  if (user) {
    const leanplum = new LeanplumService(user.uid);
    leanplum.track(actionEvent, eventProps);

    const { payload: superProperties } = await thunkAPI.dispatch(getSuperProperties());
    const mixpanel = new MixpanelService(user.uid, superProperties as SuperProperties);
    mixpanel.track(actionEvent, eventProps);
  }
});

export const trackSearchItemClicked = createAsyncThunk<
  void,
  { term?: string; contentType?: ContentTypes; uid?: string; title?: string }
>(`${NAME}/track-search-item-clicked-event`, async ({ term, contentType, uid, title }, thunkAPI) => {
  thunkAPI.dispatch(
    trackAction({
      actionEvent: ActionEvents.SearchResultClicked,
      eventProps: {
        search_term: term,
        search_filter_duration: 'all',
        search_filter_type: 'all',
        content_type: contentType,
        content_uuid: uid,
        content_name: title,
      },
    }),
  );
});

export const trackShareMeditationEvent = createAsyncThunk<
  void,
  { uid: string; target: SharePlatforms },
  { state: CombinedState<RootState> }
>(`${NAME}/track-share-event`, async ({ uid, target }, thunkAPI) => {
  const meditation = meditationSelectors.getMeditationByUid(thunkAPI.getState(), { uid });
  thunkAPI.dispatch(
    trackAction({
      actionEvent: ActionEvents.SharingEventDone,
      eventProps: {
        type: ShareTypes.Meditation,
        target_platform: target,
        content_uuid: uid,
        content_name: meditation.title,
      },
    }),
  );
});

export const trackShareMusicEvent = createAsyncThunk<
  void,
  { uid: string; target: SharePlatforms },
  { state: CombinedState<RootState> }
>(`${NAME}/track-share-event`, async ({ uid, target }, thunkAPI) => {
  const musicTrack = musicSelectors.getMusicTrackByUid(thunkAPI.getState(), { uid });
  thunkAPI.dispatch(
    trackAction({
      actionEvent: ActionEvents.SharingEventDone,
      eventProps: {
        type: ShareTypes.Music,
        target_platform: target,
        content_uuid: uid,
        content_name: musicTrack.title,
      },
    }),
  );
});

export const trackShareProgramEvent = createAsyncThunk<
  void,
  { uid: string; target: SharePlatforms },
  { state: CombinedState<RootState> }
>(`${NAME}/track-share-event`, async ({ uid, target }, thunkAPI) => {
  const program = courseSelectors.getCourseByUid(thunkAPI.getState(), { uid });

  thunkAPI.dispatch(
    trackAction({
      actionEvent: ActionEvents.SharingEventDone,
      eventProps: {
        type: ShareTypes.Program,
        target_platform: target,
        content_uuid: uid,
        content_name: program.title,
      },
    }),
  );
});

export const trackShareMomentEvent = createAsyncThunk<
  void,
  { uid: string; target: SharePlatforms },
  { state: CombinedState<RootState> }
>(`${NAME}/track-share-event`, async ({ uid, target }, thunkAPI) => {
  const moment = meditationSelectors.getMomentByUid(thunkAPI.getState(), { uid });
  const momentName = moment.title.split(' ').join('_').toLowerCase();

  thunkAPI.dispatch(
    trackAction({
      actionEvent: ActionEvents.SharingEventDone,
      eventProps: {
        type: ShareTypes.MomentCollection,
        target_platform: target,
        content_uuid: uid,
        moment_name: `${momentName}_${moment.locale}`,
      },
    }),
  );
});

export const trackShareMusicCategoryEvent = createAsyncThunk<
  void,
  { uid: string; target: SharePlatforms },
  { state: CombinedState<RootState> }
>(`${NAME}/track-share-event`, async ({ uid, target }, thunkAPI) => {
  const musicCategory = musicSelectors.getCategoryByUid(thunkAPI.getState(), { uid });
  const categoryName = musicCategory.title.split(' ').join('_').toLowerCase();

  thunkAPI.dispatch(
    trackAction({
      actionEvent: ActionEvents.SharingEventDone,
      eventProps: {
        type: ShareTypes.MusicCollection,
        target_platform: target,
        content_uuid: uid,
        moment_name: `${categoryName}_${musicCategory.locale}`,
      },
    }),
  );
});

export const trackShareQuoteEvent = createAsyncThunk<
  void,
  { uid: string; target: SharePlatforms; type?: ShareTypes.QuoteOfTheDay | ShareTypes.FinishedQuote },
  { state: CombinedState<RootState> }
>(`${NAME}/track-share-event`, async ({ uid, target, type }, thunkAPI) => {
  const quote = QuoteSelectors.getQuoteByUid(thunkAPI.getState(), { uid });

  thunkAPI.dispatch(
    trackAction({
      actionEvent: ActionEvents.SharingEventDone,
      eventProps: {
        type,
        target_platform: target,
        content_uuid: uid,
        quote: quote.quote,
      },
    }),
  );
});

export const trackMeditationSessionStart = createAsyncThunk<
  void,
  { meditationUid: string; trackUid: string; startTime: string },
  { state: CombinedState<RootState> }
>(`${NAME}/track-meditation-session--start`, async ({ meditationUid, trackUid, startTime }, thunkAPI) => {
  const meditation = meditationSelectors.getMeditationByUid(thunkAPI.getState(), { uid: meditationUid });
  const moment = meditationSelectors.getMomentByMeditationUid(thunkAPI.getState(), { uid: meditationUid });
  const track = audioTracksSelectors.getAudioTrackByUid(thunkAPI.getState(), { uid: trackUid });
  const narrator = audioTracksSelectors.getNarratorById(thunkAPI.getState(), { id: track.narrator });
  const source = getSessionSource();

  await thunkAPI.dispatch(
    trackAction({
      actionEvent: ActionEvents.SessionStarted,
      eventProps: {
        type: PlayerTypes.Meditation,
        start_datetime: startTime,
        content_name: meditation.title,
        narrator: narrator.fullName,
        version_uuid: track.uid,
        version_name: track.title,
        track_duration_in_minutes: Math.floor(track.duration / 60),
        content_language: meditation.locale,
        related_category: moment.title,
        session_source: source,
        content_format: track.type,
      },
    }),
  );
});

export const trackMeditationSessionFinished = createAsyncThunk<
  void,
  { meditationUid: string; trackUid: string; startTime: string; endTime: string; progress: number },
  { state: CombinedState<RootState> }
>(
  `${NAME}/track-meditation-session--finished`,
  async ({ meditationUid, trackUid, startTime, endTime, progress }, thunkAPI) => {
    const meditation = meditationSelectors.getMeditationByUid(thunkAPI.getState(), { uid: meditationUid });
    const moment = meditationSelectors.getMomentByMeditationUid(thunkAPI.getState(), { uid: meditationUid });
    const track = audioTracksSelectors.getAudioTrackByUid(thunkAPI.getState(), { uid: trackUid });
    const narrator = audioTracksSelectors.getNarratorById(thunkAPI.getState(), { id: track.narrator });
    const source = getSessionSource();
    const duration = getMinutesBetweenTimestamps(startTime, endTime);

    await thunkAPI.dispatch(
      trackAction({
        actionEvent: ActionEvents.SessionFinished,
        eventProps: {
          type: PlayerTypes.Meditation,
          duration,
          start_datetime: startTime,
          end_datetime: endTime,
          content_name: meditation.title,
          narrator: narrator.fullName,
          version_uuid: track.uid,
          version_name: track.title,
          track_duration_in_minutes: Math.floor(track.duration / 60),
          content_language: meditation.locale,
          related_category: moment.title,
          session_source: source,
          content_format: track.type,
          session_progress: progress,
          data_costs: Math.round(track.fileSize * (progress / 100) * 10) / 10,
        },
      }),
    );
  },
);

export const trackMeditationSessionCanceled = createAsyncThunk<
  void,
  { meditationUid: string; trackUid: string; startTime: string; endTime: string },
  { state: CombinedState<RootState> }
>(`${NAME}/track-meditation-session--canceled`, async ({ meditationUid, trackUid, startTime, endTime }, thunkAPI) => {
  const meditation = meditationSelectors.getMeditationByUid(thunkAPI.getState(), { uid: meditationUid });
  const moment = meditationSelectors.getMomentByMeditationUid(thunkAPI.getState(), { uid: meditationUid });
  const track = audioTracksSelectors.getAudioTrackByUid(thunkAPI.getState(), { uid: trackUid });
  const narrator = audioTracksSelectors.getNarratorById(thunkAPI.getState(), { id: track.narrator });
  const source = getSessionSource();
  const duration = getMinutesBetweenTimestamps(startTime, endTime);

  await thunkAPI.dispatch(
    trackAction({
      actionEvent: ActionEvents.SessionCanceled,
      eventProps: {
        type: PlayerTypes.Meditation,
        duration,
        start_datetime: startTime,
        end_datetime: endTime,
        content_name: meditation.title,
        narrator: narrator.fullName,
        version_uuid: track.uid,
        version_name: track.title,
        track_duration_in_minutes: Math.floor(track.duration / 60),
        content_language: meditation.locale,
        related_category: moment.title,
        session_source: source,
        content_format: track.type,
      },
    }),
  );
});

export const trackMusicTrackSessionStart = createAsyncThunk<
  void,
  { musicTrackUid: string; trackUid: string; musicCategoryUid?: string; source?: string; startTime: string },
  { state: CombinedState<RootState> }
>(
  `${NAME}/track-music-track-session--start`,
  async ({ musicTrackUid, trackUid, musicCategoryUid, source, startTime }, thunkAPI) => {
    const musicTrack = musicSelectors.getMusicTrackByUid(thunkAPI.getState(), { uid: musicTrackUid });
    const musicCategoryFromUid = musicSelectors.getCategoryByUid(thunkAPI.getState(), { uid: musicCategoryUid });
    const musicCategoryByTrack = musicSelectors.getCategoryByMusicTrackUid(thunkAPI.getState(), { uid: musicTrackUid });
    const track = audioTracksSelectors.getAudioTrackByUid(thunkAPI.getState(), { uid: trackUid });
    const narrator = audioTracksSelectors.getNarratorById(thunkAPI.getState(), { id: track.narrator });
    const _source = source || getSessionSource();
    const musicCategory = musicCategoryFromUid || musicCategoryByTrack;

    await thunkAPI.dispatch(
      trackAction({
        actionEvent: ActionEvents.SessionStarted,
        eventProps: {
          type: PlayerTypes.Music,
          start_datetime: startTime,
          content_name: musicTrack.title,
          narrator: narrator.fullName,
          version_uuid: track.uid,
          version_name: track.title,
          track_duration_in_minutes: Math.floor(track.duration / 60),
          content_language: musicTrack.locale,
          related_category: musicCategory?.title,
          session_source: _source,
          content_format: track.type,
        },
      }),
    );
  },
);

export const trackMusicTrackSessionFinished = createAsyncThunk<
  void,
  {
    musicTrackUid: string;
    trackUid: string;
    musicCategoryUid?: string;
    source?: string;
    startTime: string;
    endTime: string;
    progress: number;
  },
  { state: CombinedState<RootState> }
>(
  `${NAME}/track-music-track-session--finished`,
  async ({ musicTrackUid, trackUid, musicCategoryUid, source, startTime, endTime, progress }, thunkAPI) => {
    const musicTrack = musicSelectors.getMusicTrackByUid(thunkAPI.getState(), { uid: musicTrackUid });
    const musicCategoryFromUid = musicSelectors.getCategoryByUid(thunkAPI.getState(), { uid: musicCategoryUid });
    const musicCategoryByTrack = musicSelectors.getCategoryByMusicTrackUid(thunkAPI.getState(), { uid: musicTrackUid });
    const track = audioTracksSelectors.getAudioTrackByUid(thunkAPI.getState(), { uid: trackUid });
    const narrator = audioTracksSelectors.getNarratorById(thunkAPI.getState(), { id: track.narrator });
    const _source = source || getSessionSource();
    const duration = getMinutesBetweenTimestamps(startTime, endTime);
    const musicCategory = musicCategoryFromUid || musicCategoryByTrack;

    await thunkAPI.dispatch(
      trackAction({
        actionEvent: ActionEvents.SessionFinished,
        eventProps: {
          type: PlayerTypes.Music,
          duration,
          start_datetime: startTime,
          end_datetime: endTime,
          content_name: musicTrack.title,
          narrator: narrator.fullName,
          version_uuid: track.uid,
          version_name: track.title,
          track_duration_in_minutes: Math.floor(track.duration / 60),
          content_language: musicTrack.locale,
          related_category: musicCategory?.title,
          session_source: _source,
          content_format: track.type,
          session_progress: progress,
          data_costs: Math.round(track.fileSize * (progress / 100) * 10) / 10,
        },
      }),
    );
  },
);

export const trackMusicTrackSessionCanceled = createAsyncThunk<
  void,
  {
    musicTrackUid: string;
    trackUid: string;
    musicCategoryUid?: string;
    source?: string;
    startTime: string;
    endTime: string;
  },
  { state: CombinedState<RootState> }
>(
  `${NAME}/track-music-track-session--canceled`,
  async ({ musicTrackUid, trackUid, musicCategoryUid, source, startTime, endTime }, thunkAPI) => {
    const musicTrack = musicSelectors.getMusicTrackByUid(thunkAPI.getState(), { uid: musicTrackUid });
    const musicCategoryFromUid = musicSelectors.getCategoryByUid(thunkAPI.getState(), { uid: musicCategoryUid });
    const musicCategoryByTrack = musicSelectors.getCategoryByMusicTrackUid(thunkAPI.getState(), { uid: musicTrackUid });
    const track = audioTracksSelectors.getAudioTrackByUid(thunkAPI.getState(), { uid: trackUid });
    const narrator = audioTracksSelectors.getNarratorById(thunkAPI.getState(), { id: track.narrator });
    const _source = source || getSessionSource();
    const duration = getMinutesBetweenTimestamps(startTime, endTime);
    const musicCategory = musicCategoryFromUid || musicCategoryByTrack;

    await thunkAPI.dispatch(
      trackAction({
        actionEvent: ActionEvents.SessionCanceled,
        eventProps: {
          type: PlayerTypes.Music,
          duration,
          start_datetime: startTime,
          end_datetime: endTime,
          content_name: musicTrack.title,
          narrator: narrator.fullName,
          version_uuid: track.uid,
          version_name: track.title,
          track_duration_in_minutes: Math.floor(track.duration / 60),
          content_language: musicTrack.locale,
          related_category: musicCategory?.title,
          session_source: _source,
          content_format: track.type,
        },
      }),
    );
  },
);

export const trackMusicPlaylistStarted = createAsyncThunk<
  void,
  { source: SessionSourceTypes },
  { state: CombinedState<RootState> }
>(`${NAME}/track-music-playlist-started`, async () => {
  // async ({ source }, thunkAPI) => {
  // await thunkAPI.dispatch(
  //   trackAction({
  //     actionEvent: ActionEvents.PlaylistStarted,
  //     eventProps: {
  //       start_datetime: dateToIso(),
  //       category_source: source,
  //     },
  //   }),
  // );
});

export const trackMusicPlaylistFinished = createAsyncThunk<
  void,
  { tracks: string[]; startDate: string; duration: number; source: SessionSourceTypes },
  { state: CombinedState<RootState> }
>(`${NAME}/track-music-playlist-finished`, async () => {
  // async ({ source, startDate, tracks, duration }, thunkAPI) => {
  // await thunkAPI.dispatch(
  // trackAction({
  //   actionEvent: ActionEvents.PlaylistFinished,
  //   eventProps: {
  //     category_source: source,
  //     start_datetime: startDate,
  //     end_datetime: dateToIso(),
  //     amount_of_tracks: tracks.length,
  //     duration,
  //   },
  // }),
  // );
});

export const trackMusicCategoryPlaylistStarted = createAsyncThunk<
  void,
  { categoryUid: string; subCategoryUid?: string; source: SessionSourceTypes },
  { state: CombinedState<RootState> }
>(`${NAME}/track-music-category-playlist-started`, async ({ categoryUid, subCategoryUid, source }, thunkAPI) => {
  const musicCategory = musicSelectors.getCategoryByUid(thunkAPI.getState(), { uid: categoryUid });
  const musicSubCategory = musicCategory.subCategories.find(({ uid }) => uid === subCategoryUid);
  const eventProps: EventProps<ActionEvents.PlaylistStarted> = {
    music_category_name: musicCategory.title,
    start_datetime: dateToIso(),
    category_source: source,
  };

  if (subCategoryUid) {
    eventProps.music_sub_category_name = musicSubCategory.title;
  }

  // await thunkAPI.dispatch(
  //   trackAction({
  //     actionEvent: ActionEvents.PlaylistStarted,
  //     eventProps,
  //   }),
  // );
});

export const trackMusicCategoryPlaylistFinished = createAsyncThunk<
  void,
  { categoryUid: string; subCategoryUid?: string; startDate: string; duration: number; source: SessionSourceTypes },
  { state: CombinedState<RootState> }
>(
  `${NAME}/track-music-category-playlist-finished`,
  async ({ categoryUid, subCategoryUid, startDate, duration, source }, thunkAPI) => {
    const musicCategory = musicSelectors.getCategoryByUid(thunkAPI.getState(), { uid: categoryUid });
    const musicSubCategory = musicCategory.subCategories.find(({ uid }) => uid === subCategoryUid);
    const eventProps: EventProps<ActionEvents.PlaylistFinished> = {
      music_category_name: musicCategory.title,
      start_datetime: startDate,
      end_datetime: dateToIso(),
      category_source: source,
      amount_of_tracks: musicCategory.tracks.length,
      duration,
    };

    if (subCategoryUid) {
      eventProps.music_sub_category_name = musicSubCategory.title;
      eventProps.amount_of_tracks = musicSubCategory.tracks.length;
    }

    // await thunkAPI.dispatch(
    //   trackAction({
    //     actionEvent: ActionEvents.PlaylistFinished,
    //     eventProps,
    //   }),
    // );
  },
);

export const trackCourseSessionStart = createAsyncThunk<
  void,
  { courseUid: string; trackUid: string; source?: string; startTime: string },
  { state: CombinedState<RootState> }
>(`${NAME}/track-course-session--start`, async ({ courseUid, trackUid, source, startTime }, thunkAPI) => {
  const course = courseSelectors.getCourseByUid(thunkAPI.getState(), { uid: courseUid });
  const track = audioTracksSelectors.getAudioTrackByUid(thunkAPI.getState(), { uid: trackUid });
  const narrator = audioTracksSelectors.getNarratorById(thunkAPI.getState(), { id: track.narrator });
  const _source = source || getSessionSource();

  await thunkAPI.dispatch(
    trackAction({
      actionEvent: ActionEvents.SessionStarted,
      eventProps: {
        type: PlayerTypes.ProgramLesson,
        start_datetime: startTime,
        content_name: track.title,
        narrator: narrator.fullName,
        version_uuid: track.uid,
        version_name: track.label,
        track_duration_in_minutes: Math.floor(track.duration / 60),
        content_language: course.locale,
        program_name: course.title,
        session_source: _source,
        content_format: track.type,
      },
    }),
  );
});

export const trackCourseSessionFinished = createAsyncThunk<
  void,
  { courseUid: string; trackUid: string; source?: string; startTime: string; endTime: string; progress: number },
  { state: CombinedState<RootState> }
>(
  `${NAME}/track-course-session--finished`,
  async ({ courseUid, trackUid, source, startTime, endTime, progress }, thunkAPI) => {
    const course = courseSelectors.getCourseByUid(thunkAPI.getState(), { uid: courseUid });
    const track = audioTracksSelectors.getAudioTrackByUid(thunkAPI.getState(), { uid: trackUid });
    const narrator = audioTracksSelectors.getNarratorById(thunkAPI.getState(), { id: track.narrator });
    const _source = source || getSessionSource();
    const duration = getMinutesBetweenTimestamps(startTime, endTime);

    await thunkAPI.dispatch(
      trackAction({
        actionEvent: ActionEvents.SessionFinished,
        eventProps: {
          type: PlayerTypes.ProgramLesson,
          duration,
          start_datetime: startTime,
          end_datetime: endTime,
          content_name: track.title,
          narrator: narrator.fullName,
          version_uuid: track.uid,
          version_name: track.label,
          track_duration_in_minutes: Math.floor(track.duration / 60),
          content_language: course.locale,
          program_name: course.title,
          session_source: _source,
          content_format: track.type,
          session_progress: progress,
          data_costs: Math.round(track.fileSize * (progress / 100) * 10) / 10,
        },
      }),
    );
  },
);

export const trackCourseSessionCanceled = createAsyncThunk<
  void,
  { courseUid: string; trackUid: string; source: string; startTime: string; endTime: string },
  { state: CombinedState<RootState> }
>(`${NAME}/track-course-session--canceled`, async ({ courseUid, trackUid, source, startTime, endTime }, thunkAPI) => {
  const course = courseSelectors.getCourseByUid(thunkAPI.getState(), { uid: courseUid });
  const track = audioTracksSelectors.getAudioTrackByUid(thunkAPI.getState(), { uid: trackUid });
  const narrator = audioTracksSelectors.getNarratorById(thunkAPI.getState(), { id: track.narrator });
  const _source = source || getSessionSource();
  const duration = getMinutesBetweenTimestamps(startTime, endTime);

  await thunkAPI.dispatch(
    trackAction({
      actionEvent: ActionEvents.SessionCanceled,
      eventProps: {
        type: PlayerTypes.ProgramLesson,
        duration,
        start_datetime: startTime,
        end_datetime: endTime,
        content_name: track.title,
        narrator: narrator.fullName,
        version_uuid: track.uid,
        version_name: track.label,
        track_duration_in_minutes: Math.floor(track.duration / 60),
        content_language: course.locale,
        program_name: course.title,
        session_source: _source,
        content_format: track.type,
      },
    }),
  );
});

export const trackCourseStarted = createAsyncThunk<void, string, { state: CombinedState<RootState> }>(
  `${NAME}/track-course-started`,
  async (trackUid, thunkAPI) => {
    const course = courseSelectors.getCourseByTrackUid(thunkAPI.getState(), { uid: trackUid });
    await thunkAPI.dispatch(
      trackAction({
        actionEvent: ActionEvents.ProgramStarted,
        eventProps: {
          program_name: course.title,
          program_uuid: course.uid,
          datetime: dateToIso(),
        },
      }),
    );
  },
);

export const trackCourseFinished = createAsyncThunk<void, string, { state: CombinedState<RootState> }>(
  `${NAME}/track-course-finished`,
  async (trackUid, thunkAPI) => {
    const course = courseSelectors.getCourseByTrackUid(thunkAPI.getState(), { uid: trackUid });
    await thunkAPI.dispatch(
      trackAction({
        actionEvent: ActionEvents.ProgramFinished,
        eventProps: {
          program_name: course.title,
          program_uuid: course.uid,
          datetime: dateToIso(),
        },
      }),
    );
  },
);

export const trackYogaSessionStart = createAsyncThunk<
  void,
  { yogaItemUid: string; trackUid: string; startTime: string },
  { state: CombinedState<RootState> }
>(`${NAME}/track-yoga-session--start`, async ({ yogaItemUid, trackUid, startTime }, thunkAPI) => {
  const yogaItem = yogaSelectors.getItemByUid(thunkAPI.getState(), { uid: yogaItemUid });
  const track = audioTracksSelectors.getAudioTrackByUid(thunkAPI.getState(), { uid: trackUid });
  const narrator = audioTracksSelectors.getNarratorById(thunkAPI.getState(), { id: track.narrator });
  const source = getSessionSource();

  await thunkAPI.dispatch(
    trackAction({
      actionEvent: ActionEvents.SessionStarted,
      eventProps: {
        type: PlayerTypes.Yoga,
        start_datetime: startTime,
        content_name: yogaItem.title,
        narrator: narrator.fullName,
        version_uuid: track.uid,
        version_name: track.title,
        track_duration_in_minutes: Math.floor(track.duration / 60),
        content_language: yogaItem.locale,
        session_source: source,
        content_format: track.type,
      },
    }),
  );
});

export const trackYogaSessionFinished = createAsyncThunk<
  void,
  { yogaItemUid: string; trackUid: string; startTime: string; endTime: string; progress: number },
  { state: CombinedState<RootState> }
>(`${NAME}/track-yoga-session--finished`, async ({ yogaItemUid, trackUid, startTime, endTime, progress }, thunkAPI) => {
  const yogaItem = yogaSelectors.getItemByUid(thunkAPI.getState(), { uid: yogaItemUid });
  const track = audioTracksSelectors.getAudioTrackByUid(thunkAPI.getState(), { uid: trackUid });
  const narrator = audioTracksSelectors.getNarratorById(thunkAPI.getState(), { id: track.narrator });
  const source = getSessionSource();
  const duration = getMinutesBetweenTimestamps(startTime, endTime);

  await thunkAPI.dispatch(
    trackAction({
      actionEvent: ActionEvents.SessionFinished,
      eventProps: {
        type: PlayerTypes.Yoga,
        duration,
        start_datetime: startTime,
        end_datetime: endTime,
        content_name: yogaItem.title,
        narrator: narrator.fullName,
        version_uuid: track.uid,
        version_name: track.title,
        track_duration_in_minutes: Math.floor(track.duration / 60),
        content_language: yogaItem.locale,
        session_source: source,
        content_format: track.type,
        session_progress: progress,
        data_costs: Math.round(track.fileSize * (progress / 100) * 10) / 10,
      },
    }),
  );
});

export const trackYogaSessionCanceled = createAsyncThunk<
  void,
  { yogaItemUid: string; trackUid: string; startTime: string; endTime: string },
  { state: CombinedState<RootState> }
>(`${NAME}/track-yoga-session--canceled`, async ({ yogaItemUid, trackUid, startTime, endTime }, thunkAPI) => {
  const yogaItem = yogaSelectors.getItemByUid(thunkAPI.getState(), { uid: yogaItemUid });
  const track = audioTracksSelectors.getAudioTrackByUid(thunkAPI.getState(), { uid: trackUid });
  const narrator = audioTracksSelectors.getNarratorById(thunkAPI.getState(), { id: track.narrator });
  const source = getSessionSource();
  const duration = getMinutesBetweenTimestamps(startTime, endTime);

  await thunkAPI.dispatch(
    trackAction({
      actionEvent: ActionEvents.SessionCanceled,
      eventProps: {
        type: PlayerTypes.Yoga,
        duration,
        start_datetime: startTime,
        end_datetime: endTime,
        content_name: yogaItem.title,
        narrator: narrator.fullName,
        version_uuid: track.uid,
        version_name: track.title,
        track_duration_in_minutes: Math.floor(track.duration / 60),
        content_language: yogaItem.locale,
        session_source: source,
        content_format: track.type,
      },
    }),
  );
});

export const trackFlowSessionStart = createAsyncThunk<
  void,
  { flowItemUid: string; trackUid: string; startTime: string },
  { state: CombinedState<RootState> }
>(`${NAME}/track-flow-session--start`, async ({ flowItemUid, trackUid, startTime }, thunkAPI) => {
  const flowItem = flowSelectors.getItemByUid(thunkAPI.getState(), { uid: flowItemUid });
  const track = audioTracksSelectors.getAudioTrackByUid(thunkAPI.getState(), { uid: trackUid });
  const narrator = audioTracksSelectors.getNarratorById(thunkAPI.getState(), { id: track.narrator });
  const source = getSessionSource();

  await thunkAPI.dispatch(
    trackAction({
      actionEvent: ActionEvents.SessionStarted,
      eventProps: {
        type: PlayerTypes.Flow,
        start_datetime: startTime,
        content_name: flowItem.title,
        narrator: narrator.fullName,
        version_uuid: track.uid,
        version_name: track.title,
        track_duration_in_minutes: Math.floor(track.duration / 60),
        content_language: flowItem.locale,
        session_source: source,
        content_format: track.type,
      },
    }),
  );
});

export const trackFlowSessionFinished = createAsyncThunk<
  void,
  { flowItemUid: string; trackUid: string; startTime: string; endTime: string; progress: number },
  { state: CombinedState<RootState> }
>(`${NAME}/track-flow-session--finished`, async ({ flowItemUid, trackUid, startTime, endTime, progress }, thunkAPI) => {
  const flowItem = flowSelectors.getItemByUid(thunkAPI.getState(), { uid: flowItemUid });
  const track = audioTracksSelectors.getAudioTrackByUid(thunkAPI.getState(), { uid: trackUid });
  const narrator = audioTracksSelectors.getNarratorById(thunkAPI.getState(), { id: track.narrator });
  const source = getSessionSource();
  const duration = getMinutesBetweenTimestamps(startTime, endTime);

  await thunkAPI.dispatch(
    trackAction({
      actionEvent: ActionEvents.SessionFinished,
      eventProps: {
        type: PlayerTypes.Flow,
        duration,
        start_datetime: startTime,
        end_datetime: endTime,
        content_name: flowItem.title,
        narrator: narrator.fullName,
        version_uuid: track.uid,
        version_name: track.title,
        track_duration_in_minutes: Math.floor(track.duration / 60),
        content_language: flowItem.locale,
        session_source: source,
        content_format: track.type,
        session_progress: progress,
        data_costs: Math.round(track.fileSize * (progress / 100) * 10) / 10,
      },
    }),
  );
});

export const trackFlowSessionCanceled = createAsyncThunk<
  void,
  { flowItemUid: string; trackUid: string; startTime: string; endTime: string },
  { state: CombinedState<RootState> }
>(`${NAME}/track-flow-session--canceled`, async ({ flowItemUid, trackUid, startTime, endTime }, thunkAPI) => {
  const flowItem = flowSelectors.getItemByUid(thunkAPI.getState(), { uid: flowItemUid });
  const track = audioTracksSelectors.getAudioTrackByUid(thunkAPI.getState(), { uid: trackUid });
  const narrator = audioTracksSelectors.getNarratorById(thunkAPI.getState(), { id: track.narrator });
  const source = getSessionSource();
  const duration = getMinutesBetweenTimestamps(startTime, endTime);

  await thunkAPI.dispatch(
    trackAction({
      actionEvent: ActionEvents.SessionCanceled,
      eventProps: {
        type: PlayerTypes.Flow,
        duration,
        start_datetime: startTime,
        end_datetime: endTime,
        content_name: flowItem.title,
        narrator: narrator.fullName,
        version_uuid: track.uid,
        version_name: track.title,
        track_duration_in_minutes: Math.floor(track.duration / 60),
        content_language: flowItem.locale,
        session_source: source,
        content_format: track.type,
      },
    }),
  );
});
