import Leanplum from 'leanplum-sdk';
import isServer from 'utils/is-server';
import { PageEvents, MenuPageEvents } from '../models/page-events';
import { ActionEvents } from '../models/action-events';
import { EventProps } from '../models/event-props';

export default class LeanplumService {
  private static instance: typeof Leanplum;
  private static available: boolean;

  get leanplum(): typeof Leanplum {
    if (!LeanplumService.instance) {
      LeanplumService.instance = require('leanplum-sdk');
    }
    return LeanplumService.instance;
  }

  constructor(userId: string) {
    if (isServer) {
      throw new Error('LeanPlumService can only be used in the browser');
    }

    if (LeanplumService.instance) {
      return;
    }

    if (process.env.NODE_ENV === 'development') {
      this.leanplum.setAppIdForDevelopmentMode(
        process.env.NEXT_PUBLIC_LEANPLUM_APP_ID,
        process.env.NEXT_PUBLIC_LEANPLUM_ACCESS_KEY,
      );
    }
    this.leanplum.setAppIdForProductionMode(
      process.env.NEXT_PUBLIC_LEANPLUM_APP_ID,
      process.env.NEXT_PUBLIC_LEANPLUM_ACCESS_KEY,
    );
    LeanplumService.available = false;
    this.leanplum.start(userId, () => {
      LeanplumService.available = true;
    });
  }

  track<TrackEvent extends PageEvents | MenuPageEvents | ActionEvents>(
    trackEvent: TrackEvent,
    eventInfo?: EventProps<TrackEvent>,
  ): void {
    this.leanplum.track(trackEvent, eventInfo);
  }
}
